import React from "react";
import { NavLink } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { useSideBar, useWindowSize } from "../../hooks";
import routes from "../../config/routes";

type OptionsMenuProps = {
  pathLayout: string;
};

const OptionsMenu = ({ pathLayout }: OptionsMenuProps) => {
  const { isMobile } = useWindowSize();
  const { isMenuOpen, setIsMenuOpen } = useSideBar();

  // All routes that belong to the layout indicated in the pathLayout are filtered
  const dashboardRoutes = routes.find((route) => route.layout === pathLayout);

  // Animations for the title and icon of the menu options
  const menuTitleAnimation = useSpring({
    width: isMenuOpen ? "100%" : "0%",
    opacity: isMenuOpen ? 1 : 0,
  });

  /**
   * This function is only executed in xs size
   * Closes the menu when a menu option is selected
   */
  const onPressOption = () => {
    if (isMobile) {
      setIsMenuOpen(false);
    }
  };

  return (
    <>
      {dashboardRoutes?.views.map((route) => {
        const Icon = route.icon
          ? route.icon
          : () => <div className="icon-menu" />;

        return (
          <NavLink
            className="d-flex menu-item-container cursor-pointer justify-content-center align-items-center w-100"
            to={pathLayout + route.path}
            onClick={onPressOption}
            key={pathLayout + route.path}
          >
            <div
              className="d-flex px-2 icon-text-container "
              onClick={onPressOption}
            >
              <div
                className={`d-flex py-2 menu-item w-100 ${
                  isMenuOpen ? "px-2" : "px-0 justify-content-center"
                }`}
              >
                <div className="px-2">
                  <Icon className="icon-menu" />
                </div>
                <animated.span
                  className="d-inline pl-2 fs-1 text-left title-menu one-line-text"
                  style={menuTitleAnimation}
                >
                  {route.titleMenu}
                </animated.span>
              </div>
            </div>

            <div className="d-inline border-option my-3" />
          </NavLink>
        );
      })}
    </>
  );
};

export default OptionsMenu;
