import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { Input, Label, UncontrolledTooltip } from "reactstrap";
import { InputProps } from "reactstrap/lib/Input";
import { ReactComponent as Signs } from "../../assets/svg/icons/signs.svg";

interface CustomInputProps extends InputProps {
  label?: string;
  name: string;
  tooltip?: string | JSX.Element;
  newValue?: string;
  inputStyle?: React.CSSProperties;
  className?: string;
  /**
   * Defaults to 'true'
   * When set to 'false', the label prop will not be used
   */
  renderAnimation?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

const CustomInput: React.FC<CustomInputProps> = ({
  label = "",
  name,
  onChange,
  type = "text",
  autocomplete = "off",
  innerRef,
  onBlur,
  tooltip,
  newValue = "",
  inputStyle = {},
  className = "",
  renderAnimation = true,
  placeholder = "",
  disabled = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState<string | null>(null);

  const isFocusedOrHasValue = isFocused || value;

  const labelAnimation = useSpring({
    transform: isFocusedOrHasValue
      ? "translate3d(0, 0px, 0)"
      : "translate3d(0, 15px, 0)",
  });

  const renderAnimationClassNames = {
    borderAdjusted: "",
    withPlaceholder: "",
  };
  if (!renderAnimation) {
    renderAnimationClassNames.borderAdjusted = "custom-input-border-adjusted";
    renderAnimationClassNames.withPlaceholder = "custom-input-with-placeholder";
  }

  // Se utiliza newValue para cambiar el valor del input y que cambie la animación
  // cuando se setea un valor utilizando react-hook-form
  useEffect(() => {
    setValue(newValue);
  }, [newValue]);

  return (
    <div className={`${className}`}>
      {renderAnimation && (
        <animated.div style={labelAnimation}>
          <Label
            for={name}
            className={`custom-input-label ${
              isFocusedOrHasValue ? "active" : ""
            }`}
          >
            {label.toUpperCase()}
          </Label>
        </animated.div>
      )}

      {isFocusedOrHasValue && (
        <div
          className={`custom-input-border border-secondary ${renderAnimationClassNames.borderAdjusted}`}
        />
      )}

      {tooltip && (
        <div className="custom-input-tooltip" id="inputTooltip">
          <Signs />
          <UncontrolledTooltip
            placement="bottom"
            target="inputTooltip"
            fade={false}
          >
            {tooltip}
          </UncontrolledTooltip>
        </div>
      )}

      <Input
        id={name}
        name={name}
        className={`custom-input-component ${
          !isFocused && (value === "" || !value)
            ? "without-value"
            : "input-shadow"
        } ${renderAnimationClassNames.withPlaceholder}`}
        type={type}
        onFocus={() => setIsFocused(true)}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e);
          }
          setIsFocused(false);
        }}
        onChange={(e) => {
          setValue(e.target.value);
          if (onChange) {
            onChange(e);
          }
        }}
        autoComplete={autocomplete}
        innerRef={innerRef}
        style={{ paddingRight: tooltip ? "40px" : "20px", ...inputStyle }}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

export default CustomInput;
