import StepHeader from "../../components/paySteps/StepHeader";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Spinner } from "reactstrap";
import {
  PP_IS_UFRO_SYSTEM,
  PP_MAX_PAY_STEPS,
  PP_SYSTEM_TYPE,
} from "../../config/constants";
import { useStepDebt } from "../../hooks";
import { useStepsSystemMemo, useWindowSize } from "../../hooks/";
import { useQuery } from "../../hooks/useQuery";
import { IStepsSystem, StepProps } from "../../types/stepsSystem";

const PayAccounts = () => {
  const { isMobile } = useWindowSize();

  const [mounted, setMounted] = useState(false);

  const query = useQuery();
  const webpayBuyOrder = query.get("webpayBuyOrder");
  const webpayError = query.get("webpayError");
  const history = useHistory();
  const { pathname } = useLocation();

  const {
    profile,
    isLoading,
    setCurrentStep,
    currentStep,
    setDebts,
    debts,
    selectedGroup,
    setCustomer,
    customer,
    handleDebtsGroupSelection,
    systemType,
  } = useStepDebt({
    ppIsUfroSystem: PP_IS_UFRO_SYSTEM,
    ppSystemType: PP_SYSTEM_TYPE,
  });

  useEffect(
    function GoToWebPayStep() {
      if (!mounted) {
        const recoverKey = query?.get("oneclick-recover");
        let data: any = recoverKey ? localStorage.getItem(recoverKey) : null;
        data = data ? JSON.parse(data) : data;
        if (data?.customer?.rut) {
          setCustomer(data?.customer);
          setDebts(Array.isArray(data?.debts) ? data?.debts : []);
          setCurrentStep(1);
        } else {
          setCurrentStep(webpayBuyOrder || webpayError ? PP_MAX_PAY_STEPS : 0);
        }
        setMounted(true);
      }
    },
    [
      webpayBuyOrder,
      webpayError,
      setCurrentStep,
      setDebts,
      setCustomer,
      mounted,
      query,
    ]
  );

  const loggedInAndCanGoBackwards = profile && currentStep > 1;
  const notLoggedAndCanGoBackwards = !profile && currentStep > 0;
  const handleGoToPreviousStep = () => {
    if (currentStep - 1 === 0) {
      setDebts([]);
    }
    if (currentStep === PP_MAX_PAY_STEPS) {
      history.push(pathname);
    }
    setCurrentStep((curr) => curr - 1);
  };

  // Define all steps by all types system
  const stepsSystem: IStepsSystem = useStepsSystemMemo([
    customer,
    debts,
    selectedGroup,
    handleDebtsGroupSelection,
    setCurrentStep,
    setCustomer,
    setDebts,
  ] as StepProps[]);
  // Define steps to the system
  const stepsComponent = React.useMemo(() => {
    return stepsSystem[systemType]?.steps || [];
  }, [stepsSystem, systemType]);

  const isLoadingSystem =
    stepsComponent.length === 0 || systemType !== PP_SYSTEM_TYPE;
  if (isLoading || isLoadingSystem) {
    return (
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ minHeight: 300 }}
      >
        <Spinner size="lg" />
      </div>
    );
  }

  if (currentStep < stepsComponent.length - 1) {
    const StepComponent: React.FC<StepProps> =
      stepsComponent[currentStep].component;
    const props: StepProps | undefined = stepsComponent[currentStep].props;
    return (
      <>
        <StepHeader
          currentStep={currentStep}
          isMobile={isMobile}
          loggedInAndCanGoBackwards={loggedInAndCanGoBackwards}
          notLoggedAndCanGoBackwards={notLoggedAndCanGoBackwards}
          handleGoToPreviousStep={handleGoToPreviousStep}
          ppIsUfroSystem={PP_IS_UFRO_SYSTEM}
        />
        <StepComponent {...props} />
      </>
    );
  } else if (currentStep === PP_MAX_PAY_STEPS) {
    const FinalStepComponent = stepsComponent[currentStep - 1].component;
    const props: StepProps | undefined = stepsComponent[currentStep - 1].props;
    return (
      <>
        <StepHeader
          currentStep={currentStep}
          isMobile={isMobile}
          loggedInAndCanGoBackwards={loggedInAndCanGoBackwards}
          notLoggedAndCanGoBackwards={notLoggedAndCanGoBackwards}
          handleGoToPreviousStep={handleGoToPreviousStep}
          ppIsUfroSystem={PP_IS_UFRO_SYSTEM}
        />
        <FinalStepComponent {...props} />
      </>
    );
  } else {
    return <></>;
  }
};

export default PayAccounts;
