import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import es from "../locales/es.json";

i18n.use(initReactI18next).init({
  lng: "es",
  debug: false,
  resources: {
    es: {
      translations: es,
    },
  },
  fallbackLng: "es",

  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
