import { format as formatDate, parseISO } from "date-fns";
import numeral from "numeral";
import React from "react";
import { CustomInput as RSCustomInput } from "reactstrap";

import { Debt } from "../../../../types/debtType";
import UnpaidCell from "../../UnpaidCell";
import { BodyProps } from "./types";

const TBody = ({
    debts,
    selectedDebts,
    debtSelectionAllowed = false,
    withoutBlocking = false,
    blockingSelectionDebtIdx,
    showOn,
    shouldEnableRow,
    handleCheck,
    handleSaldoChange,
}: BodyProps) => {

    const renderDebtSelection = (debt: Debt, disableRow: boolean, i: number) => (
        (withoutBlocking || debtSelectionAllowed) ? (
            <>
                <td className={`text-right`}>
                    <input
                        className="input-table text-right"
                        type="text"
                        value={
                            i in selectedDebts
                                ? `$ ${numeral(selectedDebts[i]).format()}`
                                : "$ 0"
                        }
                        disabled={!(i in selectedDebts) || disableRow}
                        onChange={(e) =>
                            handleSaldoChange(e, i, debt.SALDO)
                        }
                        style={{
                            maxWidth: 149,
                            width: "100%",
                            minWidth: 80,
                        }}
                    />
                </td>
                <td>
                    <RSCustomInput
                        type="checkbox"
                        id={`item_${i}`}
                        name={`item_${i}`}
                        checked={i in selectedDebts}
                        onChange={(e: any) =>
                            handleCheck(e, i, debt?.SALDO, debt.INTATRASO)
                        }
                        disabled={disableRow}
                        style={{ minWidth: "0 !important" }}
                    />
                </td>
            </>
        ) : (
            <>
                {blockingSelectionDebtIdx === i ? (
                    <UnpaidCell debtId={`${i}`} />
                ) : (
                    <td></td>
                )}
            </>
        )
    )

    return (
        <tbody>
            {debts.map((debt: Debt, i: number) => {
                const disableRow =
                    !shouldEnableRow(i) && debtSelectionAllowed;
                return (
                    <tr
                        key={`debt_${i}`}
                        className={`text-center ${disableRow ? "disabled" : ""}`}
                    >
                        <td className="border-hover"></td>
                        <td>{debt.TIPO_DOCUMENTO}</td>
                        <td className={showOn("mobile")}>
                            {formatDate(
                                parseISO(debt?.FEC_VENCIMIENTO?.substring(0, 10)),
                                "dd-MM-yyyy"
                            )}
                        </td>
                        <td className={showOn("desktop")}>
                            {debt.TD_NMB_DOCUMENTO}
                        </td>
                        <td className={showOn("desktop")}>{debt.DOCUMENTO}</td>
                        <td className={`${showOn("desktop")} text-nowrap`}>
                            {debt.CUOTA}
                        </td>
                        <td className={`${showOn("desktop")} text-nowrap`}>
                            {formatDate(
                                parseISO(debt?.FEC_VENCIMIENTO?.substring(0, 10)),
                                "dd-MM-yyyy"
                            )}
                        </td>
                        <td className={showOn("desktop")}>
                            $ {numeral(debt?.SALDO).format()}
                        </td>
                        <td className={showOn("desktop")}>$ {numeral(debt.INTATRASO).format()}</td>
                        <td className={`font-weight-bold text-nowrap `}>
                            $ {numeral(debt?.SALDO + debt.INTATRASO).format()}
                        </td>
                        {renderDebtSelection(debt, disableRow, i)}
                    </tr>
                );
            })}
        </tbody>
    );
};
export default TBody;
