import { useContext } from "react";
import { AppContext } from "../providers/AppProvider";
import { AppActionTypes } from "../types/appTypes";

/**
 * Custom hook that provides the status and status management of the sidebar
 */
export const useSideBar = () => {
  const { state, dispatch } = useContext(AppContext);

  const setIsMenuOpen = (isOpen: boolean | null) => {
    dispatch({
      type: AppActionTypes.SET_IS_MENU_OPEN,
      payload: isOpen,
    });
  };

  const toggleMenu = () => {
    dispatch({
      type: AppActionTypes.SET_IS_MENU_OPEN,
      payload: !state.isMenuOpen,
    });
  };

  return {
    isMenuOpen: state.isMenuOpen,
    setIsMenuOpen,
    toggleMenu,
  };
};
