import React from "react";
import { ToastContainer, toast } from "react-toastify";

// Styles overrides in src/assets/scss/_custom_toast.scss
import { ReactComponent as AlertCloseIcon } from "../../assets/svg/icons/alert-close.svg";
import { ReactComponent as CheckCircleIcon } from "../../assets/svg/icons/check-circle.svg";

export const CustomToastContainer = () => {
  return (
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      closeButton={false}
    />
  );
};

export const customToast = {
  error: (text: string) => {
    const content = (
      <div className="CustomToast__content">
        <div className="CustomToast__icon">
          <AlertCloseIcon />
        </div>
        <div className="CustomToast__text">{text}</div>
      </div>
    );
    toast.error(content);
  },
  success: (text: string) => {
    const content = (
      <div className="CustomToast__content">
        <div className="CustomToast__icon">
          <CheckCircleIcon height="20" width="20"/>
        </div>
        <div className="CustomToast__text">{text}</div>
      </div>
    );
    toast.success(content);
  },
};
