import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import AuthSingleCard from "../../components/auth/AuthSingleCard";
import CustomButton from "../../components/button/CustomButton";
import { t } from "../../utils/utils";
import { ReactComponent as CheckCircleIcon } from "../../assets/svg/icons/check-circle.svg";
import { ReactComponent as WarningIcon } from "../../assets/svg/icons/alert.svg";
import {requestActivateAccount} from "../../api/request";

const ActivateAccount = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    const validateToken = async (emailToken: string) => {
      try {
        await requestActivateAccount(emailToken);
        setSuccess(true);
      } catch (err) {
        setError(true);
      }
    }
    validateToken(token)
  }, [token]);

  if (success) {
    return (
      <AuthSingleCard
        icon={
          <CheckCircleIcon
            className="svg-secondary"
            width="41px"
            height="41px"
          />
        }
        title={t("activate_account_success_title")}
        message={t("activate_account_success_content")}
      >
        <Row className="px-1 px-sm-5">
          <Col xs={12}>
            <div className="py-4">
              <CustomButton
                type="button"
                text={t("go_to_login")}
                onClick={() => history.push("/auth/login")}
              />
            </div>
          </Col>
        </Row>
      </AuthSingleCard>
    );
  } else if (error) {
    return (
      <AuthSingleCard
        icon={
          <WarningIcon className="svg-secondary" width="42px" height="42px" />
        }
        title={t("invalid_link_title")}
        message={t("invalid_link_msg") + process.env.REACT_APP_CONTACT_EMAIL}
        displayLoginLink={false}
      />
    )
  } else {
    return (
      <div
        className="spinner-border text-secondary spinner-border spinner-border-sm"
        role="status"
      >
        <span className="sr-only">{t("loading")}...</span>
      </div>
    )
  }
};

export default ActivateAccount;
