import React, { useMemo } from "react";
import { PP_MAX_PAY_STEPS } from "../../config/constants";
import { t } from "../../utils/utils";
import BackBtn from "../button/BackBtn";
import Steps from "../steps/Steps";

interface IStepHeader {
    currentStep: number,
    isMobile: boolean,
    loggedInAndCanGoBackwards: boolean | null,
    notLoggedAndCanGoBackwards: boolean,
    handleGoToPreviousStep: () => void,
    ppIsUfroSystem: boolean,
}

const StepHeader = ({ currentStep, isMobile, loggedInAndCanGoBackwards, notLoggedAndCanGoBackwards, handleGoToPreviousStep, ppIsUfroSystem }: IStepHeader) => {
    const steps = useMemo(() => (
        ppIsUfroSystem ? [t("search"), t("pay"), t("voucher")] : [t("search"), t("select"), t("pay"), t("voucher")]
    ), [ppIsUfroSystem])
    return (
        <>
            {!isMobile && (loggedInAndCanGoBackwards || notLoggedAndCanGoBackwards) && (
                <div className="float-left mt-2">
                    <BackBtn
                        text={
                            currentStep === PP_MAX_PAY_STEPS ? t(`goBackToBeginning`) : t(`goToPreviousStep`)
                        }
                        onClick={handleGoToPreviousStep}
                    />
                </div>
            )
            }
            <div className="mx-auto container-max-600">
                <Steps steps={steps} currentStep={currentStep} />
            </div>
            {
                isMobile && (loggedInAndCanGoBackwards || notLoggedAndCanGoBackwards) && (
                    <div className="mt-5">
                        <BackBtn
                            text={t(`goToPreviousStep`)}
                            onClick={handleGoToPreviousStep}
                        />
                    </div>
                )
            }
        </>
    )
}

export default StepHeader