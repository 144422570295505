import React from "react";
import { useForm, Controller } from "react-hook-form";
import { FormGroup, FormText } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { PP_ENABLE_RESTRICTION_REGISTER, RECAPTCHA_SITE_KEY } from "../../config/constants";
import { validate as validateRut, clean, format } from "rut.js";
import { t } from "../../utils/utils";

import CustomInput from "../../components/input/CustomInput";
import CustomButton from "../../components/button/CustomButton";
import { requestRUTVerification } from "../../api/request";
import CustomAlert from "../../components/alert/CustomAlert";

const DEFAULT_VALUES = {
  rut: "",
  token: "",
};

type FormValues = typeof DEFAULT_VALUES;

export interface PersonData {
  rut: string;
  name: string;
  lastName: string;
}

interface Props {
  onRutVerificationSuccess?: (person: PersonData) => void;
}

export default function RutSearch({
  onRutVerificationSuccess = () => null,
}: Props) {
  const {
    control,
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { isValid, isSubmitting },
    setValue,
    errors,
  } = useForm({
    mode: "onChange",
    defaultValues: DEFAULT_VALUES,
  });
  let recaptchaRef = React.createRef<ReCAPTCHA | null>();
  const [newRut, setNewRut] = React.useState<string>();
  const [notFound, setNotFound] = React.useState(false);

  const searchRut = async (values: FormValues) => {
    const recaptchaToken = values.token;
    try {
      const res =
        await requestRUTVerification(values.rut, recaptchaToken);

      let person = {
        name: "",
        lastName: "",
        rut: `${values.rut}`,
      };

      if (PP_ENABLE_RESTRICTION_REGISTER) {
        const {
          data: {
            NOMBRE_PERSONA: name,
            APE_PATERNO: paternalLastName,
            APE_MATERNO: maternalLastName,
            RUT: rut,
            DVR: rutDigit,
          },
        } = res;
        person = {
          name,
          lastName: `${paternalLastName || ""} ${maternalLastName || ""}`,
          rut: `${rut}-${rutDigit}`,
        };
      }
      onRutVerificationSuccess(person);
      return;
    } catch (err) {
      setNotFound(true);
    } finally {
      if (recaptchaRef.current) {
        reset();
        recaptchaRef.current.reset();
      }
    }
  };

  const formatRut = () => {
    const rut = getValues("rut");
    if (clean(rut).length > 1) {
      setValue("rut", format(clean(getValues("rut"))));
      setNewRut(format(clean(getValues("rut"))));
    } else {
      setValue("rut", clean(getValues("rut")));
      setNewRut(clean(getValues("rut")));
    }
  };

  return (
    <form onSubmit={handleSubmit(searchRut)} className="px-3 py-4 mt-n5">
      {notFound && <CustomAlert type="error" text={t(`rut_not_found`)} />}
      <FormGroup className="mt-4">
        <CustomInput
          label={t("label_rut")}
          name="rut"
          onBlur={formatRut}
          newValue={newRut}
          innerRef={register({
            required: t("rut_required"),
            validate: (value: string) => {
              if (!validateRut(clean(value))) {
                return t("invalid_rut");
              } else {
                return undefined;
              }
            },
          })}
        />
        <FormText color="danger">{errors.rut?.message}</FormText>
      </FormGroup>

      <FormGroup className="pt-4 g-recaptcha-center">
        <Controller
          control={control}
          name="token"
          rules={{ required: true }}
          render={({ onChange, ref }) => {
            recaptchaRef = ref;
            return (
              <ReCAPTCHA
                ref={ref}
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={onChange}
                hl={t("language_code")}
                size="normal"
              />
            );
          }}
        />
      </FormGroup>
      <CustomButton
        text={t("next")}
        className="mt-5"
        disabled={!isValid || isSubmitting}
        loading={isSubmitting}
        type="submit"
      />
    </form>
  );
}
