import React from "react";
import { Col, FormText, Row } from "reactstrap";
import { t } from "../../../../utils/utils";

import CustomInput from "../../../input/CustomInput";
import { AnonymousUserInputsProps } from "./types";

const AnonymousUserInputs = ({
  isLogged,
  payerEmail,
  setPayerEmail,
  formState,
  register,
}: AnonymousUserInputsProps) => {
  const emailRef = register({
    required: t("required_field"),
    pattern: {
      //eslint-disable-next-line
      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      message: t("invalid_email"),
    },
  });

  return !isLogged ? (
    <div className="container-max-600 ml-auto pb-2">
      <Row className="justify-content-end">
        <Col sm={6} className="pr-3 pr-sm-3">
          <CustomInput
            label={t("label_your_email")}
            name="payerEmail"
            onChange={(e: any) => setPayerEmail(e.target.value)}
            newValue={payerEmail}
            innerRef={emailRef}
          />
          <FormText color="danger">
            {formState.errors.payerEmail?.message}
          </FormText>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <p className="mb-0 text-left pt-1 pb-2 fs-1-1">
            {t("anonymous_email_instructions")}
          </p>
        </Col>
      </Row>
    </div>
  ) : null;
};

export default AnonymousUserInputs;
