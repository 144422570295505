import React from "react";
import { Redirect } from "react-router-dom";
import { Col, Row } from "reactstrap";
import LayoutRoutes from "../components/routes/LayoutRoutes";
import { useUserState } from "../hooks";

const AuthLayout = () => {
  const { isLogged } = useUserState();

  if (isLogged) {
    return <Redirect to="/user-dashboard/index" />;
  }
  return (
    <div className="vh-100">
      <Row className="m-0 min-vh-100">
        <Col xs={12}>
          {/* Render all routes associated with the Authlayout */}
          <LayoutRoutes pathLayout="/auth" defaultViewPath="/login" />
        </Col>
      </Row>
    </div>
  );
};

export default AuthLayout;
