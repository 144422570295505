import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import { useLocation } from "react-router-dom";
import { ReactComponent as MenuCloseIcon } from "../../assets/svg/icons/menu-close.svg";
import routes, { LayoutRouteType, ViewRouteType } from "../../config/routes";
import { useSideBar, useUserState } from "../../hooks";
import ProfileMenu from "./ProfileMenu";

type HeaderProps = {
  pathLayout: string;
};

/**
 * Renders the title of the active menu and the username in case of being authenticated
 * @param pathLayout is the path of the layout in which the header is located
 */
const Header = ({ pathLayout }: HeaderProps) => {
  const location = useLocation();
  const { toggleMenu } = useSideBar();
  const [activeMenu, setActiveMenu] = useState<ViewRouteType | null>(null);
  const { profile } = useUserState();

  useEffect(() => {
    // Obtains the active route
    const layoutRoutes = routes.find(
      (route: LayoutRouteType) => route.layout === pathLayout
    );
    const menu = layoutRoutes?.views.find((view) => {
      return location.pathname.startsWith(pathLayout + view.path);
    });

    if (menu) {
      setActiveMenu(menu);
    }
  }, [location, pathLayout]);

  return (
    <>
      <Row className="py-4 header-container">
        <Col xs={3} className="d-block d-sm-none">
          <div className="cursor-pointer icon-bars" onClick={toggleMenu}>
            <MenuCloseIcon />
          </div>
        </Col>
        <Col xs={6} className="d-none d-sm-block">
          <span className="fs-2 color-medium">{activeMenu?.titleHeader}</span>
        </Col>
        <Col
          xs={9}
          sm={6}
          className="d-flex justify-content-end align-items-center"
          id="profile-card"
        >
          <ProfileMenu
            email={profile?.email}
            name={profile?.name}
            lastName={profile?.lastName}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="d-block d-sm-none py-3">
          <span className="fs-2 color-medium">{activeMenu?.titleHeader}</span>
        </Col>
      </Row>
    </>
  );
};

export default Header;
