import { useCallback, useEffect, useState } from "react";
import { DebtListType, DebtsGroup } from "../types/debtType";
import { PP_ENABLE_RESTRICTION_BY_DEBT_ORDER } from "./../config/constants";
import { useQuery } from "./useQuery";
import { GatewayOpenOptions } from "../components/paySteps/Step2/GatewayModal";
import { addToast } from "@octano/global-ui";

//diccionario para el objeto de deudas seleccionadas
type SelectedDebtsType = {
  //la key debe ser numerica, el valor corresponde al abono a pagar
  [key: number]: number;
};

export const useDebtsSelection = (
  isLogged: boolean,
  customer?: { rut: string; name: string },
  debts: DebtListType | undefined = [],
  callConfirmation?: (params: GatewayOpenOptions) => void
) => {
  const [mounted, setMounted] = useState(false);
  const [allDebtsCheched, setAllDebtsCheched] = useState(false);
  const [selectedDebts, setSelectedDebts] = useState<SelectedDebtsType>({});

  const query = useQuery();

  const getLastSelectedIndex = useCallback(() => {
    return Object.keys(selectedDebts)
      .map((key) => +key)
      .sort((a, b) => b - a)[0];
  }, [selectedDebts]);

  const handleCheckAll = useCallback(
    (debts: DebtListType | DebtsGroup["debts"]) => {
      //si ya estaba seleccionada, significa que quiero sacarlas todas
      if (allDebtsCheched) {
        //reinicio el objeto de deudas seleccionadas
        setSelectedDebts({});
      } else {
        const updatedDebts: SelectedDebtsType = { ...selectedDebts };
        //recorro todas las deudas
        debts.forEach((debt, i: number) => {
          //si no estan en el objeto, las agrego con valor (abono) del saldo total
          if (!(i in selectedDebts)) {
            updatedDebts[i] = debt.SALDO + debt.INTATRASO;
          }
        });
        setSelectedDebts(updatedDebts);
      }
      setAllDebtsCheched(!allDebtsCheched);
    },
    [allDebtsCheched, selectedDebts]
  );

  const handleSaldoChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, i: number, saldo: number) => {
      let newAmmount: number = 0;

      if (e?.target?.value) {
        const stringAmount: string = e.target.value.replaceAll(
          /\s+|\.+|\$+/g,
          ""
        );

        // Nuevo monto valido, se reemplaza
        if (!isNaN(+stringAmount)) {
          newAmmount = +stringAmount > saldo ? saldo : +stringAmount;
        }
      }

      // Prospecto a nuevas deudas seleccionadas
      let updatedDebts = {
        ...selectedDebts,
        [i]: newAmmount,
      };

      // Deselecciomos las deudas posteriores en caso de ser necesario
      if (PP_ENABLE_RESTRICTION_BY_DEBT_ORDER) {
        if (newAmmount < saldo) {
          updatedDebts = Object.fromEntries(
            Object.entries(updatedDebts).filter((entry) => +entry[0] <= i)
          );
        }
      }
      setSelectedDebts(updatedDebts);
    },
    [selectedDebts]
  );

  const handleCheck = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      i: number,
      saldo: number,
      interest: number
    ) => {
      // si selecciono la deuda, la agrego al objeto de deudas
      // como valor (abono) pongo el saldo total
      if (e?.target?.checked) {
        setSelectedDebts({ ...selectedDebts, [i]: saldo + interest });
      } else {
        const updatedDebts = Object.fromEntries(
          Object.entries(selectedDebts).filter(
            PP_ENABLE_RESTRICTION_BY_DEBT_ORDER
              ? (entry) => +entry[0] < i
              : (entry) => +entry[0] !== i
          )
        );

        setSelectedDebts(updatedDebts);
        //al sacar una del objeto ya no están todas seleccionadas
        setAllDebtsCheched(false);
      }
    },
    [selectedDebts]
  );

  const handleSaveInStorage = useCallback(
    (key: string) => {
      localStorage.setItem(
        key,
        JSON.stringify({
          customer,
          debts,
          allDebtsCheched: allDebtsCheched ? "checked" : "none",
          selectedDebts,
        })
      );
    },
    [allDebtsCheched, selectedDebts, customer, debts]
  );

  const retreiveFromStorage = useCallback(
    (key: string, tokenConfirmation?: string | null) => {
      let data: any = localStorage.getItem(key);
      if (data) {
        data = JSON.parse(data);
      }
      setAllDebtsCheched(data?.allDebtsCheched === "checked");
      setSelectedDebts(data?.selectedDebts ?? {});

      if (tokenConfirmation) {
        callConfirmation &&
          callConfirmation({
            debts,
            selectedDebts: data?.selectedDebts ?? {},
            isLogged: !!isLogged,
            payerRut: data?.customer?.rut,
            payerEmail: data?.customer?.email,
            confirmationToken: tokenConfirmation,
          });
      }
    },
    [callConfirmation, debts, isLogged]
  );

  useEffect(() => {
    if (!mounted) {
      const recoverKey = query?.get("oneclick-recover");
      if (recoverKey) {
        retreiveFromStorage(recoverKey, query?.get("TBK_TOKEN"));
        if (query?.get("showErrorWebpay")) {
          addToast({
            icon: "error",
            color: "danger",
            text: query?.get("showErrorWebpay") || "",
          });
        }
        window.history.replaceState(
          {},
          document.title,
          "/user-dashboard/index"
        );
      }
      setMounted(true);
    }
  }, [mounted, query, retreiveFromStorage]);

  return {
    selectedDebts,
    allDebtsCheched,
    setAllDebtsCheched,
    handleCheckAll,
    handleSaldoChange,
    handleCheck,
    getLastSelectedIndex,
    handleSaveInStorage,
  };
};
