import numeral from "numeral";
import React from "react";
import { Col, Row } from "reactstrap";
import { t } from "../../../../utils/utils";

interface ITFooter {
    totalAmountToPay: number
}

export const TFooter = ({ totalAmountToPay }: ITFooter) => {
    return (<Row className="px-3 mt-2">
        <Col
            xs={12}
            className="px-0"
            style={{
                backgroundColor: "#f4f4f4",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
            }}
        >
            <div
                className="container-max-250 d-flex justify-content-center ml-auto bg-primary"
                style={{ borderBottomRightRadius: "4px" }}
            >
                <span className="text-white font-weight-bold py-2">
                    {t("total_to_pay")}: ${numeral(totalAmountToPay).format()}
                </span>
            </div>
        </Col>
    </Row>)
}