import React from "react";
import { Col, Row } from "reactstrap";

import { ReactComponent as AlertIcon } from "../../assets/svg/icons/alert.svg";
import { ReactComponent as InfoIcon } from "../../assets/svg/icons/info.svg";

type WebpayErrorProps = {
  type: "warning" | "info";
  title: string;
  instructions: string;
  details?: JSX.Element | null;
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
};
const ResultAlert = ({
  type,
  title,
  instructions,
  details,
  containerClassName = "",
  containerStyle = {},
}: WebpayErrorProps) => {
  const icon =
    type === "info" ? (
      <InfoIcon className="svg-secondary" width="60px" height="60px" />
    ) : (
      <AlertIcon className="svg-secondary" width="60px" height="60px" />
    );
  return (
    <div
      className={`container-max-400 mx-auto ${containerClassName}`}
      style={containerStyle}
    >
      <Row>
        <Col xs={12} className="text-center p-4">
          {icon}
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-center fs-1-5 color-dark px-4 pb-4">
          {title}
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-center fs-1-3 color-light px-5 pb-5">
          {instructions}
        </Col>
      </Row>
      {details ? (
        <div className="bg-color-body custom-rounded px-4 py-3 line-height-2">
          {details}
        </div>
      ) : null}
    </div>
  );
};

export default ResultAlert;
