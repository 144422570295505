import React, { useState } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Row,
} from "reactstrap";
import CustomAlert from "../../components/alert/CustomAlert";
import CustomButton from "../../components/button/CustomButton";
import CustomInput from "../../components/input/CustomInput";
import Logo from "../../components/logo/Logo";
import { ReactComponent as LoginImage } from "../../assets/svg/login_image.svg";
import { ReactComponent as LogoGlobal } from "../../assets/svg/logo_global_color.svg";
import { requestLogin } from "../../api/request";
import { useUserState } from "../../hooks";
import { emailRegex, setJWT, t } from "../../utils/utils";
import { Layouts } from "../../config/constants";
import ForgotPasswordModal from "./ForgotPasswordModal";
import RecoveryEmailSentModal from "./RecoveryEmailSentModal";

type Inputs = {
  email: string;
  password: string;
};

enum ModalType {
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  RECOVERY_EMAIL_SENT = "RECOVERY_EMAIL_SENT",
  NONE = "NONE",
}

const Auth = () => {
  const [openedModal, setOpenedModal] = useState<ModalType>(ModalType.NONE);
  const { isLogged, setIsLogged } = useUserState();
  const history = useHistory();
  const {
    handleSubmit,
    register,
    formState: { isValid, isSubmitting },
    errors,
  } = useForm<Inputs>({
    mode: "onChange",
  });
  const [errorForm, setErrorForm] = useState<string>();

  const onLogin = async (values: Inputs) => {
    try {
      const res = await requestLogin(values.email, values.password);
      if (res?.data?.accessToken) {
        setJWT(res.data.accessToken);
        setIsLogged(true);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        setErrorForm(t("invalid_login"));
      } else {
        setErrorForm(t("unexpected_error_msg"));
      }
    }
  };

  const enterWithoutAuthentication = () => {
    history.push(Layouts.DASHBOARD);
  };

  if (isLogged) {
    return <Redirect to={Layouts.USER_DASHBOARD} />;
  }
  return (
    <>
      <ForgotPasswordModal
        isOpen={openedModal === ModalType.FORGOT_PASSWORD}
        onCancel={() => setOpenedModal(ModalType.NONE)}
        onEmailSent={() => setOpenedModal(ModalType.RECOVERY_EMAIL_SENT)}
      />
      <RecoveryEmailSentModal
        isOpen={openedModal === ModalType.RECOVERY_EMAIL_SENT}
        onConfirm={() => setOpenedModal(ModalType.NONE)}
      />

      <Container className="px-1 px-xl-5 d-flex">
        <Row className="py-3 flex-grow-1 justify-content-center align-items-center min-vh-100">
          <Col lg={6} className="p-0 d-none d-lg-block">
            <Card
              body
              className="card-authentication bg-image-login rounded-xxl px-0 pt-3 pb-0 ml-xl-5 mr-lg-n3"
            >
              <Row className="mx-0 pt-4 pb-5">
                <Col className="p-2" xs={6}>
                  <LogoGlobal
                    className="svg-white"
                    style={{ width: "100%", height: "3.5rem" }}
                  />
                </Col>
              </Row>
              <Row className="row flex-grow-1">
                <Col className="p-0 d-flex align-items-end">
                  <LoginImage style={{ width: "100%" }} />
                </Col>
              </Row>
            </Card>
          </Col>

          {/* LOGIN CARD */}
          <Col md={9} lg={6} className="p-lg-0 mx-auto">
            <Card
              body
              className="login-card px-lg-5 rounded-lg mr-xl-5 ml-lg-n3"
              style={{ minHeight: "600px" }}
            >
              <Row className="pt-4 pb-2 px-1 px-sm-5">
                <Col xs={12} className="text-center">
                  <Logo
                    alt="logo"
                    style={{ maxHeight: 120 }}
                    src="/tenant/logo.svg"
                    fallbackSrc="/tenant/logo.png"
                  />
                </Col>
                <Col xs={12}>
                  <p className="mb-0 text-center pt-4 pb-2 fs-1-1">
                    {t("login_msg_1")} <b>{t("login_msg_2")}</b>{" "}
                    {t("login_msg_3")}
                  </p>
                </Col>
                <Col xs={12}>
                  {errorForm && <CustomAlert type="error" text={errorForm} />}
                  <Form onSubmit={handleSubmit(onLogin)}>
                    <FormGroup className="pt-4">
                      <CustomInput
                        label={t("email_label")}
                        name="email"
                        innerRef={register({
                          required: t("email_required"),
                          pattern: {
                            //eslint-disable-next-line
                            value: emailRegex,
                            message: t("login_invalid_email"),
                          },
                        })}
                      />
                      <FormText color="danger">
                        {errors.email?.message}
                      </FormText>
                    </FormGroup>
                    <FormGroup className="pt-4">
                      <CustomInput
                        label={t("password")}
                        name="password"
                        type="password"
                        innerRef={register({
                          required: t("password_required"),
                        })}
                      />
                    </FormGroup>
                    <div className="py-4">
                      <CustomButton
                        type="submit"
                        disabled={!isValid || isSubmitting}
                        loading={isSubmitting}
                        text={t("login")}
                      />
                    </div>
                  </Form>
                </Col>
                <Col xs={12} className="pb-1 text-center">
                  <button
                    onClick={() => setOpenedModal(ModalType.FORGOT_PASSWORD)}
                    className="text-center btn btn-link"
                  >
                    {t("forgot_password")}
                  </button>
                </Col>
                <Col xs={12} className="text-center">
                  <span> {t("no_account")}</span>{" "}
                  <Link
                    to="/auth/create-account"
                    className="mb-0 text-center text-underline primary-link"
                  >
                    {t("create_account")}
                  </Link>
                  <hr />
                </Col>
                <Col xs={12}>
                  <CustomButton
                    colorType="secondary"
                    text={t("pay_unaunthenticated")}
                    onClick={enterWithoutAuthentication}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Auth;
