import { AppState, AppAction, AppActionTypes } from "../types/appTypes";

export const initialState: AppState = {
  isMenuOpen: null,
};

const appReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case AppActionTypes.RESET_STATE:
      return {
        ...initialState,
      };

    case AppActionTypes.SET_IS_MENU_OPEN:
      return {
        ...state,
        isMenuOpen: action.payload,
      };

    default:
      return state;
  }
};

export default appReducer;
