import React from "react";
import fileSaver from "file-saver";
import clsx from "clsx";

import { t } from "../../utils/utils";
import { ReactComponent as IconPdf } from "../../assets/svg/icons/pdf.svg";
import { requestDownloadPdfVoucher } from "../../api/request";
import { customToast } from "../../components/toast/CustomToast";
import { useUserState } from "../../hooks";

type DownloadPdfIconProps = {
  buyOrder: number;
  filename: string;
  centered?: boolean;
};

const PdfDownloadIcon = ({
  buyOrder,
  filename,
  centered,
}: DownloadPdfIconProps) => {
  const { isLogged } = useUserState();
  const [isLoading, setIsLoading] = React.useState(false);

  const onClick = async () => {
    setIsLoading(true);
    try {
      const res = await requestDownloadPdfVoucher(String(buyOrder), isLogged);
      setIsLoading(false);
      if (res.status !== 200) {
        customToast.error(t("button_download_voucher_error"));
      } else {
        fileSaver.saveAs(res.data, filename);
      }
    } catch (error) {
      setIsLoading(false);
      customToast.error(t("button_download_voucher_error"));
    }
  };

  return isLoading ? (
    <div
      className="spinner-border text-secondary spinner-border spinner-border-sm"
      role="status"
    >
      <span className="sr-only">{t("loading")}...</span>
    </div>
  ) : (
    <div
      className={clsx(
        "cursor-pointer",
        !centered && "mt-n2 mb-n2 mr-n2 pt-2 pb-2 pr-2"
      )}
      onClick={onClick}
    >
      <IconPdf className="svg-primary" />
    </div>
  );
};

export default PdfDownloadIcon;
