import React from "react";
import { Alert } from "reactstrap";
import { ReactComponent as ErrorIcon } from "../../assets/svg/icons/error.svg";
import { ReactComponent as WarningIcon } from "../../assets/svg/icons/alert.svg";
import { ReactComponent as InfoIcon } from "../../assets/svg/icons/info.svg";

type CustomAlertProps = {
  type: "error" | "warning" | "info";
  text: string;
};

const CustomAlert = ({ type, text }: CustomAlertProps) => {
  const renderIcon = () => {
    if (type === "error") {
      return <ErrorIcon width={20} height={20} />;
    }
    if (type === "warning") {
      return <WarningIcon width={20} height={20} />;
    }
    return <InfoIcon width={20} height={20} />;
  };

  return (
    <Alert className="d-flex flex-row my-0" color={type}>
      <div className="pr-2">{renderIcon()}</div>
      <div className="w-100">{text}</div>
    </Alert>
  );
};

export default CustomAlert;
