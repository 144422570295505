import React from "react";
import { useSpring, animated } from "react-spring";
import { useSideBar } from "../../hooks";
import Logo from "../logo/Logo";

const MenuLogo = () => {
  const { isMenuOpen } = useSideBar();
  const contractedAnimation = useSpring({
    opacity: isMenuOpen ? 0 : 1,
  });

  const expandedAnimation = useSpring({
    opacity: isMenuOpen ? 1 : 0,
  });

  return (
    <>
      {/* LOGO EXPANDED */}
      <animated.div
        className={`${isMenuOpen ? "d-flex" : "d-none"
          } justify-content-center align-items-center`}
        style={expandedAnimation}
      >
        <Logo
          alt="logo"
          style={{ maxHeight: 60 }}
          src="/tenant/logo_expanded.svg"
          fallbackSrc="/tenant/logo_expanded.png"
        />
      </animated.div>
      {/* LOGO SMALL */}
      <animated.div
        className={`${isMenuOpen ? "d-none" : "d-flex"
          } justify-content-center align-items-center`}
        style={contractedAnimation}
      >
        <Logo
          alt="logo"
          style={{ maxHeight: 60 }}
          src="/tenant/logo_small.svg"
          fallbackSrc="/tenant/logo_small.png"
        />
      </animated.div>
    </>
  );
};

export default React.memo(MenuLogo);
