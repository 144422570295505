import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";
import { useUserState, useWindowSize } from "../../../../hooks";
import { t } from "../../../../utils/utils";
import CustomButton from "../../../button/CustomButton";
import CustomerInfo from "../../CustomerInfo";
import WebpayPostRedirect from "../../WebpayPostRedirect";

import { useDebtsSelection } from "../../../../hooks/useDebtsSelection";
import AnonymousUserInputs from "./AnonymousUserInputs";
import Body from "./TBody";
import { TFooter } from "./TFooter";
import Header from "./THeader";
import { PayerRut, Step2Props } from "./types";
import GatewayModal, {
  GatewayModalMethods,
  GatewayOpenOptions,
} from "../GatewayModal";

const Step2 = ({ setCurrentStep, customer, debts }: Step2Props) => {
  const { isLogged } = useUserState();
  const { isMobile } = useWindowSize();

  const gateWayRef = useRef<GatewayModalMethods>(null);

  const [isPayLoading] = useState<boolean>(false);
  const [webpayRedirect] = useState<{
    token: string;
    initURL: string;
  }>();

  const handleOpenGateway = useCallback((opt: GatewayOpenOptions) => {
    gateWayRef?.current?.open(opt);
  }, []);

  const {
    selectedDebts,
    allDebtsCheched,
    setAllDebtsCheched,
    handleCheckAll,
    handleSaldoChange,
    handleCheck,
    getLastSelectedIndex,
    handleSaveInStorage,
  } = useDebtsSelection(isLogged, customer, debts, handleOpenGateway);

  const [payerRut, setPayerRut] = useState("");
  const [payerEmail, setPayerEmail] = useState("");

  useEffect(() => {
    //verifico si todas las deudas estan seleccionadas
    if (Object.keys(selectedDebts).length === debts.length) {
      setAllDebtsCheched(true);
    }
  }, [selectedDebts, debts.length, setAllDebtsCheched]);

  //operación para sumar el total a pagar, segun seleccionadas y el abono
  const sumAmountToPay = (): number => {
    const debtsToSum: number[] = Object.keys(selectedDebts).map((d) => +d);
    return debtsToSum.reduce(
      (debtsSum: number, debt: number) => debtsSum + selectedDebts[debt],
      0
    );
  };

  const totalAmountToPay: number = sumAmountToPay();

  const showOn = (type: "mobile" | "desktop") => {
    if ((isMobile && type === "desktop") || (!isMobile && type === "mobile")) {
      return "d-none";
    } else {
      return "";
    }
  };

  const { register, formState, setValue, getValues } = useForm<PayerRut>({
    mode: "onChange",
  });

  const isPayButtonEnabled =
    totalAmountToPay > 0 &&
    (isLogged || (payerRut !== "" && formState.isValid));

  const shouldEnableRow = (rowIdx: number) => {
    const lastSelected = getLastSelectedIndex();
    const haveSelectedSomething = !isNaN(lastSelected);

    const coveredFullAmountOfPreviousDebt =
      selectedDebts[rowIdx - 1] &&
      selectedDebts[rowIdx - 1] ===
        debts[rowIdx - 1].SALDO + debts[rowIdx - 1].INTATRASO;

    return haveSelectedSomething && coveredFullAmountOfPreviousDebt
      ? rowIdx <= lastSelected + 1
      : rowIdx === 0;
  };

  const blockingSelectionDebtIdx = useMemo(() => {
    return debts.findIndex((debt: any) => debt.CANAL === "PROT");
  }, [debts]);

  const debtSelectionAllowed = useMemo(() => {
    return blockingSelectionDebtIdx === -1;
  }, [blockingSelectionDebtIdx]);

  return (
    <>
      <CustomerInfo rut={customer?.rut} name={customer?.name} />
      <Row>
        <Col xs={12} className="pb-3">
          <div className="table-responsive fixed-header debt-list">
            <table className="table custom-table">
              <Header
                showOn={showOn}
                allDebtsCheched={allDebtsCheched}
                onCheckAll={() => handleCheckAll(debts)}
                blockDebtSelection={!debtSelectionAllowed}
              />
              <Body
                debts={debts}
                selectedDebts={selectedDebts}
                debtSelectionAllowed={debtSelectionAllowed}
                showOn={showOn}
                shouldEnableRow={shouldEnableRow}
                blockingSelectionDebtIdx={blockingSelectionDebtIdx}
                handleCheck={handleCheck}
                handleSaldoChange={handleSaldoChange}
              />
            </table>
          </div>
          {debts.length === 0 ? null : (
            <TFooter totalAmountToPay={totalAmountToPay} />
          )}
        </Col>
      </Row>

      {debts.length === 0 ? null : (
        <>
          <AnonymousUserInputs
            isLogged={isLogged}
            payerRut={payerRut}
            setPayerRut={setPayerRut}
            payerEmail={payerEmail}
            setPayerEmail={setPayerEmail}
            formState={formState}
            getValues={getValues}
            setValue={setValue}
            register={register}
          />
          <div className="container-max-400 ml-auto">
            <Row>
              <Col xs={6} className="pr-1 pr-sm-2">
                <CustomButton
                  type="button"
                  text={t("cancel")}
                  colorType="secondary"
                  onClick={() => setCurrentStep(0)}
                />
              </Col>
              <Col xs={6} className="pl-1 pl-sm-2">
                <CustomButton
                  type="button"
                  text={t("pay")}
                  disabled={!isPayButtonEnabled}
                  onClick={() =>
                    handleOpenGateway({
                      debts,
                      selectedDebts,
                      isLogged,
                      payerRut,
                      payerEmail,
                    })
                  }
                  loading={isPayLoading}
                />
              </Col>
              {webpayRedirect ? (
                <WebpayPostRedirect {...webpayRedirect} />
              ) : null}
            </Row>
          </div>
        </>
      )}
      <GatewayModal ref={gateWayRef} onRequestSaveState={handleSaveInStorage} />
    </>
  );
};

export default Step2;
