import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Container, Row } from "reactstrap";
import Logo from "../logo/Logo";
import { ReactComponent as ChevronRight } from "../../assets/svg/icons/chevron-right-solid.svg";
import { ReactComponent as LogoGlobal } from "../../assets/svg/logo_global_color.svg";
import { t } from "../../utils/utils";

type AuthSingleCardProps = {
  icon?: React.ReactElement;
  title?: string;
  message?: string;
  displayLoginLink?: boolean;
  displayFooter?: boolean;
};

const AuthSingleCard: FunctionComponent<AuthSingleCardProps> = ({
  icon,
  children,
  title,
  message,
  displayLoginLink = true,
  displayFooter = true,
}) => {
  return (
    <div className="d-flex flex-column flex-grow-1 min-vh-100">
      <Container className="d-flex flex-grow-1 justify-content-center align-items-center py-3 px-0">
        <div>
          <Card
            body
            className="login-card rounded-lg px-4 px-lg-5"
            style={{ width: "100%", maxWidth: "500px" }}
          >
            <div>
              <Row className="pt-4 px-1 px-sm-5">
                <Col xs={12} className="text-center">
                  <Logo
                    alt="logo"
                    style={{ maxHeight: "60px" }}
                    src="/tenant/logo_expanded.svg"
                    fallbackSrc="/tenant/logo_expanded.png"
                  />
                </Col>
              </Row>
              {icon && (
                <Row className="text-center pt-5">
                  <Col xs={12}>{icon}</Col>
                </Row>
              )}
              {title && (
                <Row className="text-center px-4 px-sm-5">
                  <Col xs={12} className="pt-4">
                    <span className="fs-1-4 color-dark">{title}</span>
                  </Col>
                </Row>
              )}
              {message && (
                <Row className="text-center px-4 px-sm-5">
                  <Col xs={12} className="pt-3">
                    <p className="color-light fs-1-2">{message}</p>
                  </Col>
                </Row>
              )}
              {children}
            </div>
          </Card>
          {displayLoginLink && (
            <div className="text-center py-3">
              <Link
                to="/auth/login"
                className="mb-0 text-center text-underline primary-link fs-1-1 pr-2"
              >
                {t("go_back_to_login")}
              </Link>
              <ChevronRight width="8px" className="svg-primary" />
            </div>
          )}
        </div>
      </Container>
      {displayFooter && (
        <div className="text-center text-sm-right">
          <div className="px-4 pb-4">
            <LogoGlobal
              style={{
                maxWidth: "100%",
                height: "41px",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthSingleCard;
