import clsx from "clsx";
import React from "react";
import { CustomInput as RSCustomInput } from "reactstrap";

import { t } from "../../../../utils/utils";
import { HeaderProps } from "./types";

const THeader = ({
    showOn,
    allDebtsCheched,
    onCheckAll,
    blockDebtSelection = false,
}: HeaderProps) => {
    return (
        <thead>
            <tr className="text-nowrap text-center">
                <th colSpan={2}>{t("type")}</th>
                <th className={showOn("desktop")}>{t("detail")}</th>
                <th className={showOn("desktop")}>{t("n_doc")}</th>
                <th className={showOn("desktop")}>{t("n_quota")}</th>
                <th style={{ whiteSpace: "break-spaces" }}>{t("expiration_date")}</th>
                <th className={showOn("desktop")}>{t(`capital_amount`)}</th>
                <th className={showOn("desktop")}>{t("interest")}</th>
                <th
                    className={clsx(
                        showOn("desktop"),
                        blockDebtSelection && "text-right"
                    )}
                >
                    {t("total_amount")}
                </th>
                <th className={showOn("mobile")}>{t("amount")}</th>
                {blockDebtSelection ? (
                    <th>{t("status")}</th>
                ) : (
                    <>
                        <th className={`text-right`}>{t("amount_to_pay")}</th>
                        <th>
                            <RSCustomInput
                                id="checkAll"
                                name="checkAll"
                                type="checkbox"
                                checked={allDebtsCheched}
                                onChange={onCheckAll}
                            />
                        </th>
                    </>
                )}
            </tr>
        </thead>
    );
};
export default THeader;
