import { useEffect, useState } from "react";
import { requestGroupedDebtsAuthorized } from './../api/request';
import { DebtsGroup } from './../types/debtType';
import { TSystemType } from "./../types/stepsSystem";

import { useUserState } from './useUserState';

interface IUseStepDebt {
    ppIsUfroSystem: boolean
    ppSystemType: string
}

export const useStepDebt = ({ ppIsUfroSystem, ppSystemType }: IUseStepDebt) => {
    const { profile } = useUserState();
    const [isLoading, setIsLoading] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [debts, setDebts] = useState<DebtsGroup[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<DebtsGroup>();
    const [customer, setCustomer] = useState<{
        rut: string;
        name: string;
    } | null>(null);
    
    useEffect(
        function SkipSearchAndFetchGroups() {
            const fetchDebtsGroups = async () => {
                setIsLoading(true);
                const res = await requestGroupedDebtsAuthorized(profile?.rut || "");
                if (res.data) {
                    setDebts(res.data.debtsGroups);
                }
                setIsLoading(false);
            };

            if (!ppIsUfroSystem && profile && currentStep === 0) {
                fetchDebtsGroups().then(() => {
                    setCustomer({
                        rut: profile.rut,
                        name: `${profile.name} ${profile.lastName}`,
                    });
                    setCurrentStep(1);
                });
            }
        },
        [profile, currentStep, ppIsUfroSystem]
    );

    const [systemType, setSystemType] = useState<TSystemType>("");
    useEffect(() => {
        setTimeout(() => {
            setSystemType(ppSystemType as TSystemType)
        }, 1500);
    }, [ppSystemType])

    const handleDebtsGroupSelection = (debtsGroup: DebtsGroup) => {
        setSelectedGroup(debtsGroup);
        setCurrentStep(2);
    };

    return {
        profile,
        isLoading,
        setCurrentStep,
        currentStep,
        setDebts,
        debts,
        selectedGroup,
        customer,
        setCustomer,
        handleDebtsGroupSelection,
        systemType
    }
}