import { useMemo } from 'react';
import GroupSelectionStep from "../components/paySteps/GroupSelectionStep";
import Step1 from "../components/paySteps/Step1";
import Step2SDT from "../components/paySteps/Step2/SDT";
import Step2Ufro from "../components/paySteps/Step2/UFRO";
import Step4 from "../components/paySteps/Step4";
import { IStepsSystem, StepProps } from '../types/stepsSystem';

export const useStepsSystemMemo = (dependencies: StepProps[]): IStepsSystem => {
    const [customer, debts, selectedGroup, handleDebtsGroupSelection, setCurrentStep, setCustomer, setDebts] = dependencies
    return useMemo(() => {
        return {
            'SDT': {
                steps: [
                    {
                        name: 'Step1',
                        component: Step1,
                        props: {
                            setCurrentStep: setCurrentStep,
                            setDebts: setDebts,
                            setCustomer: setCustomer
                        }
                    },
                    {
                        name: 'GroupSelectionStep',
                        component: GroupSelectionStep,
                        props: {
                            customer: customer,
                            debtsGroups: debts,
                            onDebtsGroupSelection: handleDebtsGroupSelection,
                        }
                    },
                    {
                        name: 'Step2',
                        component: Step2SDT,
                        props: {
                            setCurrentStep: setCurrentStep,
                            customer: customer,
                            debts: selectedGroup?.debts,
                        }
                    },
                    {
                        name: 'Step4',
                        component: Step4
                    },
                ],
            },
            'UFRO': {
                steps: [
                    {
                        name: 'Step1',
                        component: Step1,
                        props: {
                            setCurrentStep: setCurrentStep,
                            setDebts: setDebts,
                            setCustomer: setCustomer
                        }
                    },
                    {
                        name: 'Step2',
                        component: Step2Ufro,
                        props: {
                            setCurrentStep: setCurrentStep,
                            customer: customer,
                            debts: debts,
                        }
                    },
                    {
                        name: 'Step4',
                        component: Step4
                    },
                ],
            },
        }
    }, [customer, debts, selectedGroup, handleDebtsGroupSelection, setCurrentStep, setCustomer, setDebts]);
};