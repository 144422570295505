import * as React from "react";
import fileSaver from "file-saver";
import CustomButton from "../button/CustomButton";
import { t } from "../../utils/utils";
import { requestDownloadPdfVoucher } from "../../api/request";
import { customToast } from "../toast/CustomToast";
import { useUserState } from "../../hooks";

interface DownloadPdfVoucherButtonProps {
  buyOrder: string;
  filename: string;
}

const DownloadPdfVoucherButton: React.FC<DownloadPdfVoucherButtonProps> = ({
  buyOrder,
  filename,
}) => {
  const { isLogged } = useUserState();
  const [isLoading, setIsLoading] = React.useState(false);

  const onClick = async () => {
    setIsLoading(true);
    const res = await requestDownloadPdfVoucher(buyOrder, isLogged);
    if (res.status !== 200) {
      customToast.error(t("button_download_voucher_error"));
    } else {
      fileSaver.saveAs(res.data, filename);
    }
    setIsLoading(false);
  };

  return (
    <>
      <CustomButton
        text={t("button_download_voucher")}
        onClick={onClick}
        loading={isLoading}
      />
    </>
  );
};

export default DownloadPdfVoucherButton;
