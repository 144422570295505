import { useContext, useCallback, useEffect } from "react";
import { UserContext } from "../providers/UserProvider";
import { ProfileType, UserActionTypes, UserSessionEvent } from "../types/userTypes";
import { removeJWT } from "../utils/utils";

/**
 * Custom hook that provides the status and status management of the Logged User
 */
export const useUserState = () => {
  const { state, dispatch } = useContext(UserContext);

  const resetUserState = useCallback(async () => {
    removeJWT();
    dispatch({
      type: UserActionTypes.RESET_STATE,
    });
  }, [dispatch]);

  const setIsLogged = useCallback(
    (isLogged: boolean) => {
      dispatch({
        type: UserActionTypes.SET_IS_LOGGED,
        payload: isLogged,
      });
    },
    [dispatch]
  );

  const setProfile = useCallback(
    (profile: ProfileType) => {
      dispatch({
        type: UserActionTypes.SET_PROFILE,
        payload: profile,
      });
    },
    [dispatch]
  );

  const setIsSessionExpired = useCallback(
    (isSessionExpired: boolean) => {
      dispatch({
        type: UserActionTypes.SET_IS_SESSION_EXPIRED,
        payload: isSessionExpired,
      });
    },
    [dispatch]
  );

  // Handling session expiration
  useEffect(() => {
    const notifySessionExpiration = () => {
      setIsSessionExpired(true);
    };

    window.addEventListener(UserSessionEvent.EXPIRED, notifySessionExpiration);

    return () => {
      window.removeEventListener(
        UserSessionEvent.EXPIRED,
        notifySessionExpiration,
      );
    };
  }, [setIsSessionExpired]);

  return {
    resetUserState,
    isLogged: state.isLogged,
    isSessionExpired: state.isSessionExpired,
    profile: state.profile,
    setProfile,
    setIsLogged,
    setIsSessionExpired,
  };
};
