import React from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup, FormText } from "reactstrap";
import { clean as cleanRut, format, validate as validateRut } from "rut.js";
import CustomButton from "../../components/button/CustomButton";
import CustomInput from "../../components/input/CustomInput";
import NewPasswordInputs from "../../components/newPasswordInputs/NewPasswordInputs";
import { t } from "../../utils/utils";

const DEFAULT_VALUES = {
  name: "",
  lastName: "",
  phone: "",
  email: "",
  password: "",
  repeatPassword: "",
  rut: "",
};

export type FormValues = typeof DEFAULT_VALUES;

const getRequiredValueError = (value: string, error: string) => {
  if (value.trim() === "") {
    return error;
  }
  return undefined;
};

export type InputsCreateAccount = {
  rut: string;
  name: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  repeatPassword: string;
};

type CreateAccountFormProps = {
  step: 1 | 2;
  setStep: React.Dispatch<React.SetStateAction<2 | 1>>;
  onCreateAccount: (values: InputsCreateAccount) => void;
  initialValues?: Partial<FormValues>;
};

const CreateAccountForm = ({
  step,
  onCreateAccount,
  setStep,
  initialValues,
}: CreateAccountFormProps) => {
  const {
    handleSubmit,
    register,
    errors,
    formState: { isSubmitting, isValid },
    setValue,
    getValues,
    trigger,
  } = useForm<InputsCreateAccount>({
    mode: "onChange",
    defaultValues: {
      ...DEFAULT_VALUES,
      ...initialValues,
    },
  });

  const formatRut = (e: React.FocusEvent<HTMLInputElement>) => {
    if (format(e.target.value).length > 1) {
      setValue("rut", format(e.target.value));
    }
  };

  const handleCreateAccount = (values: FormValues) => {
    return onCreateAccount({ ...values, rut: initialValues?.rut || values.rut });
  };

  return (
    <Form onSubmit={handleSubmit(handleCreateAccount)}>
      <div className={step === 1 ? "" : "d-none"}>
        <FormGroup>
          <CustomInput
            label={t("name")}
            name="name"
            innerRef={register({
              required: t("required_field"),
              validate: (value) =>
                getRequiredValueError(value, t("invalid_name")),
            })}
            newValue={getValues().name}
          />
          <FormText color="danger">{errors.name?.message}</FormText>
        </FormGroup>
        <FormGroup>
          <CustomInput
            label={t("lastname")}
            name="lastName"
            innerRef={register({
              required: t("required_field"),
              validate: (value) => {
                if (value.trim() === "") {
                  return t("invalid_lastname");
                }
                return undefined;
              },
            })}
            newValue={getValues().lastName}
          />
          <FormText color="danger">{errors.lastName?.message}</FormText>
        </FormGroup>
        <FormGroup>
          <CustomInput
            label={t("phone")}
            name="phone"
            innerRef={register({
              required: t("required_field"),
              validate: (value) =>
                getRequiredValueError(value, t("invalid_phone")),
              pattern: {
                //eslint-disable-next-line
                value: /^[+]?[0-9]{7,11}$/,
                message: t("invalid_phone"),
              },
            })}
          />
          <FormText color="danger">{errors.phone?.message}</FormText>
        </FormGroup>
        <div className="py-4">
          <CustomButton
            type="button"
            disabled={!isValid || isSubmitting}
            text={t("next")}
            onClick={() => setStep(2)}
          />
        </div>
      </div>

      {step === 2 && (
        <div>
          <FormGroup>
            <CustomInput
              label={t("rut")}
              name="rut"
              innerRef={register({
                required: t("rut_required"),
                validate: (value) => {
                  if (!validateRut(cleanRut(value))) {
                    return t("validate_rut");
                  }
                  return undefined;
                },
              })}
              onBlur={formatRut}
              disabled={!!initialValues?.rut}
              newValue={getValues().rut}
            />
            <FormText color="danger">{errors.rut?.message}</FormText>
          </FormGroup>
          <FormGroup>
            <CustomInput
              label={t("email")}
              name="email"
              innerRef={register({
                required: t("required_field"),
                pattern: {
                  //eslint-disable-next-line
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: t("invalid_email"),
                },
              })}
            />
            <FormText color="danger">{errors.email?.message}</FormText>
          </FormGroup>
          <NewPasswordInputs
            trigger={trigger}
            getValues={getValues}
            errors={errors}
            register={register}
          />
          <div className="py-4">
            <CustomButton
              type="submit"
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              text={t("button_create_account")}
            />
          </div>
        </div>
      )}
    </Form>
  );
};

export default CreateAccountForm;
