import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { ReactComponent as InfoIcon } from "../../assets/svg/icons/info.svg";
import { t } from "../../utils/utils";

interface Props {
  debtId: string;
}

export default function UnpaidCell({ debtId = "" }: Props) {
  const tooltipId = `unpaid-cell-tooltipId-${debtId}`;
  return (
    <td>
      {t("unpaid")}
      <span id={tooltipId} className="d-inline-block ml-1">
        <InfoIcon width={12} height={12} className="svg-secondary" />
      </span>
      <UncontrolledTooltip placement="top" target={tooltipId}>
        {t("unpaid_tooltip")}
      </UncontrolledTooltip>
    </td>
  );
}
