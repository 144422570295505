import React from "react";
import { Col, Row } from "reactstrap";
import numeral from "numeral";
import { format as formatDate, parseISO } from "date-fns";

import { t } from "../../utils/utils";
import { ReactComponent as CheckCircleIcon } from "../../assets/svg/icons/check-circle.svg";
import CustomButton from "../button/CustomButton";

type PaymentInfoProps = { children: React.ReactNode };

const PaymentInfo = ({ children }: PaymentInfoProps) => {
  return (
    <div className="mx-auto container-max-400 bg-color-body custom-rounded">
      <Row className="px-3 py-4">
        <Col xs={12} className="payment-info-container">
          <div className="table-responsive">{children}</div>
        </Col>
      </Row>
    </div>
  );
};

type PaymentResult = {
  CORRELATIVO: string;
  MONTO: number;
  COD_AUTH: string;
  FEC_TRANSA: string;
  TIPO_PAGO: string;
  CANT_CUOTA: number;
  TIPO_CUOTA: number;
  NRO_TARJETA: string;
  FECHA_DE_COMPRA: string;
  HORA_DE_COMPRA: string;
};

type PaymentInfoTableProps = { paymentInfo: PaymentResult };

const PaymentInfoTable = ({ paymentInfo }: PaymentInfoTableProps) => {
  return (
    <table className="table custom-table-info px-l">
      <tbody>
        <tr>
          <th scope="row">{t("buy_order")}:</th>
          <td>{paymentInfo.CORRELATIVO}</td>
        </tr>
        <tr>
          <th scope="row">{t("order_date")}:</th>
          <td>{paymentInfo.FECHA_DE_COMPRA}</td>
        </tr>
        <tr>
          <th scope="row">{t("order_time")}:</th>
          <td>{paymentInfo.HORA_DE_COMPRA}</td>
        </tr>
        <tr>
          <th scope="row">{t("amount")}:</th>
          <td>$ {numeral(paymentInfo.MONTO).format()}</td>
        </tr>
        <tr>
          <th scope="row">{t("authorization_code")}:</th>
          <td>{paymentInfo.COD_AUTH}</td>
        </tr>
        <tr>
          <th scope="row">{t("transaction_date")}:</th>
          <td>
            {formatDate(parseISO(paymentInfo.FEC_TRANSA), "dd-MM-yyy")}{" "}
            {formatDate(new Date(paymentInfo.FEC_TRANSA), "dd-MM-yyy")}
          </td>
        </tr>
        <tr>
          <th scope="row">{t("payment_type")}:</th>
          <td>{paymentInfo.TIPO_PAGO}</td>
        </tr>
        <tr>
          <th scope="row">{t("installment_quantity")}:</th>
          <td>{paymentInfo.CANT_CUOTA}</td>
        </tr>
        <tr>
          <th scope="row">{t("banking_card")}:</th>
          <td>**** **** **** {paymentInfo.NRO_TARJETA}</td>
        </tr>
      </tbody>
    </table>
  );
};

const WebpaySuccessMessage = () => {
  return (
    <div className="container-max-400 mx-auto pb-4">
      <Row>
        <Col xs={12} className="text-center p-4">
          <CheckCircleIcon
            className="svg-secondary"
            width="60px"
            height="60px"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="text-center fs-1-5 color-dark">
          {t("payment_success")}
        </Col>
      </Row>
    </div>
  );
};

type WebpaySuccessInfoProps = {
  orderData: PaymentResult | undefined;
  error: string;
  request: () => {};
  isLoading: boolean;
};

const WebpaySuccessInfo = ({
  orderData,
  error,
  request,
  isLoading,
}: WebpaySuccessInfoProps) => {
  return (
    <div>
      <WebpaySuccessMessage />
      <PaymentInfo>
        {!orderData && !error ? (
          <div
            className="spinner-border text-secondary spinner-border spinner-border-lg"
            role="status"
          >
            <span className="sr-only">{t("loading")}...</span>
          </div>
        ) : null}
        {orderData ? <PaymentInfoTable paymentInfo={orderData} /> : null}
        {error === "unknown error" ? (
          <>
            <div className="text-center fs-1-15 color-light pb-4 px-4">
              {t("error_could_not_get_payment_info")}
            </div>
            <div className="px-3">
              <CustomButton
                text={t("retry")}
                onClick={request}
                loading={isLoading}
              />
            </div>
          </>
        ) : null}
      </PaymentInfo>
    </div>
  );
};

export default WebpaySuccessInfo;
