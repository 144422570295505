import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

// Components
import MethodsBlock from "./MethodsBlock";
import {
  Button,
  Dialog,
  Modal,
  addToast,
  showDialogConfirm,
} from "@octano/global-ui";
import GatewayRedirect, { GatewayRedirectMethods } from "./GatewayRedirect";
import WebpayPlus, { WebpayPlusMethods } from "./Methods/WebpayPlus";
import Oneclick, { OneclickMethods } from "./Methods/Oneclick";

// I18n
import { useTranslation } from "react-i18next";

// Utils
import { setAnonymousUserEmail, setOrderJWT } from "../../../../utils/utils";

// Services
import {
  useConfirmTBInscriptionMutation,
  useGetInscriptionsQuery,
  useGetTBInscriptionTokenMutation,
  useRemoveInscriptionsMutation,
} from "../../../../views/pay/api";

// Types
import { DebtListType } from "../../../../types/debtType";
import { SelectedDebtsType } from "../UFRO/types";
import { OneclickInscription } from "../../../../views/pay/types";

// Types
export type GatewayOpenOptions = {
  debts: DebtListType;
  selectedDebts: SelectedDebtsType;
  isLogged: boolean;
  payerRut: string;
  payerEmail: string;
  confirmationToken?: string;
};

export type GatewayModalMethods = {
  open: (opt: GatewayOpenOptions) => void;
  close: () => void;
};

type GatewayMethod = {
  id: string;
  title: string;
  params?: any;
};

type SelectedMethod = GatewayMethod | OneclickInscription;

type GatewayModalProps = {
  onRequestSaveState?: (key: string) => void;
};

const keyPrefix = "gateway-modal-";

const BASE_METHODS: GatewayMethod[] = [
  {
    id: "webpay-plus",
    title: "Pagar con Webpay Plus",
  },
];

// Render
const GatewayModal = (
  { onRequestSaveState }: GatewayModalProps,
  ref: React.Ref<GatewayModalMethods>
) => {
  const { t } = useTranslation();

  const gatewatRedirectRef = useRef<GatewayRedirectMethods>(null);
  const oneClickRef = useRef<OneclickMethods>(null);
  const webpayPlusRef = useRef<WebpayPlusMethods>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);
  const [options, setOptions] = useState<GatewayOpenOptions>();
  const [selectedMethod, setSelectedMethod] = useState<SelectedMethod>();

  const [getInscriptionToken, { isLoading: isCreatingMethod }] =
    useGetTBInscriptionTokenMutation();
  const [confirmInscription, { isLoading: isConfirmating }] =
    useConfirmTBInscriptionMutation();
  const [removeInscription, { isLoading: isRemoving }] =
    useRemoveInscriptionsMutation();

  const {
    data = [],
    refetch,
    isUninitialized,
    isFetching: isFetchingMethods,
  } = useGetInscriptionsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const addedMethods = useMemo(() => data?.length || 0, [data?.length]);
  const isLoading = useMemo(
    () =>
      !!(
        isCreatingMethod ||
        isSubmiting ||
        isFetchingMethods ||
        isRemoving ||
        isConfirmating
      ),
    [
      isCreatingMethod,
      isSubmiting,
      isFetchingMethods,
      isRemoving,
      isConfirmating,
    ]
  );

  const handleRemove = useCallback(
    async (token: string) => {
      try {
        await removeInscription(token)?.unwrap();
        if (!isUninitialized) {
          await refetch()?.unwrap();
        }
        addToast({
          icon: "success",
          color: "success",
          text: t(`${keyPrefix}successRemove`),
        });
      } catch (_error: any) {
        addToast({
          icon: "error",
          color: "danger",
          text: _error?.data?.message?.trim() || t("unexpected_error_msg"),
        });
      }
    },
    [removeInscription, isUninitialized, refetch, t]
  );

  const handleRequestRemove = useCallback(
    async (token: string) => {
      try {
        showDialogConfirm({
          title: t(`${keyPrefix}remove-title`),
          subtitle: t(`${keyPrefix}remove-subtitle`),
          btnClose: {
            text: t(`${keyPrefix}remove-cancel`),
          },
          btnConfirm: {
            text: t(`${keyPrefix}remove-confirm`),
            onConfirm: () => handleRemove(token),
          },
        });
      } catch (_error) {}
    },
    [handleRemove, t]
  );

  const handleAddMethod = useCallback(
    async (confirmationToken: string) => {
      try {
        await confirmInscription(confirmationToken)?.unwrap();
        if (!isUninitialized) {
          await refetch()?.unwrap();
        }
        addToast({
          icon: "success",
          color: "success",
          text: t(`${keyPrefix}successAdd`),
        });
      } catch (_error: any) {
        addToast({
          icon: "error",
          color: "danger",
          text: _error?.data?.message?.trim() || t("unexpected_error_msg"),
        });
      }
    },
    [confirmInscription, isUninitialized, refetch, t]
  );

  const handleOpen = useCallback(
    (opt: GatewayOpenOptions) => {
      setIsOpen(true);
      setOptions(opt);
      if (opt?.confirmationToken) {
        handleAddMethod(opt?.confirmationToken);
      } else if (!isUninitialized) {
        refetch();
      }
    },
    [handleAddMethod, isUninitialized, refetch]
  );

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleCreate = useCallback(async () => {
    if (isLoading) {
      return;
    }
    try {
      const response = await getInscriptionToken()?.unwrap();
      onRequestSaveState && onRequestSaveState(response?.recoveryKey);

      gatewatRedirectRef?.current?.redirect({
        code: "oneclick",
        method: "POST",
        action: response?.url_webpay,
        inputs: [
          {
            name: "TBK_TOKEN",
            value: response?.token,
          },
          {
            name: "token_ws",
            value: response?.token,
          },
        ],
      });
    } catch (_error) {}
  }, [isLoading, getInscriptionToken, onRequestSaveState, gatewatRedirectRef]);

  const handleRequestCreate = useCallback(async () => {
    try {
      if (addedMethods) {
        showDialogConfirm({
          title: t(`${keyPrefix}replace-title`),
          subtitle: t(`${keyPrefix}replace-subtitle`),
          btnClose: {
            text: t(`${keyPrefix}replace-cancel`),
          },
          btnConfirm: {
            text: t(`${keyPrefix}replace-confirm`),
            onConfirm: () => handleCreate(),
          },
        });
      } else {
        await handleCreate();
      }
    } catch (_error) {}
  }, [addedMethods, handleCreate, t]);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  const handlePressItem = useCallback(
    (item: SelectedMethod) => {
      if (item === selectedMethod) {
        setSelectedMethod(undefined);
      } else {
        setSelectedMethod(item);
      }
    },
    [selectedMethod]
  );

  const handleConfirm = useCallback(async () => {
    if (isLoading) {
      return;
    }
    try {
      if ((selectedMethod as GatewayMethod)?.id === "webpay-plus") {
        if (options) {
          webpayPlusRef?.current?.handle(options);
        } else {
          addToast({
            icon: "error",
            color: "danger",
            text: t("unexpected_error_msg"),
          });
        }
      } else if ((selectedMethod as OneclickInscription)?.token) {
        if (options) {
          oneClickRef?.current?.handle(options);
        } else {
          addToast({
            icon: "error",
            color: "danger",
            text: t("unexpected_error_msg"),
          });
        }
      }
    } catch (_error) {}
  }, [isLoading, selectedMethod, webpayPlusRef, oneClickRef, options, t]);

  return (
    <>
      <Modal isOpen={isOpen} toggle={handleToggle}>
        <div className="gateway-modal d-flex w-100 flex-column align-items-center">
          <span className="d-block w-100 text-dark fs-22 lh-30 text-center mb-2">
            {t(`${keyPrefix}title`)}
          </span>
          <span className="d-block w-100 text-light fs-16 lh-20 text-center mb-4">
            {t(`${keyPrefix}subtitle`)}
          </span>

          <div className="w-100">
            <MethodsBlock
              title={t(`${keyPrefix}mainMethods`)}
              items={BASE_METHODS}
              className="mb-4"
              isSelected={(e) => e === selectedMethod}
              renderLabel={(e) => e?.title}
              onPressItem={handlePressItem}
            />

            {!!(options?.isLogged && !isRemoving && !isConfirmating) && (
              <MethodsBlock
                title={t(`${keyPrefix}alternativeMethods`)}
                items={data}
                className="mb-5"
                isSelected={(e) => e === selectedMethod}
                isDestroyable={() => !isLoading}
                renderLabel={(e) =>
                  t(`${keyPrefix}card`, {
                    brand: e?.cardType,
                    last4: e?.cardNumber,
                  })
                }
                isLoading={isLoading}
                buttonIcon={addedMethods ? "refresh" : "plus"}
                buttonText={t(
                  `${keyPrefix}${addedMethods ? "replace" : "add"}`
                )}
                onPressItem={handlePressItem}
                onPressButton={handleRequestCreate}
                onPressDestroy={(item) => handleRequestRemove(item?.token)}
              />
            )}
          </div>

          <div className="container-fluid px-0 mt-4">
            <div className="row wrap">
              <div className="col-12 col-md-6">
                <Button
                  className="w-100 mb-2"
                  onClick={handleClose}
                  type="button"
                  text={t(`${keyPrefix}cancel`)}
                  outlined
                  disabled={isLoading}
                />
              </div>
              <div className="col-12 col-md-6">
                <Button
                  className="w-100 mb-2"
                  onClick={handleConfirm}
                  type="button"
                  text={t(`${keyPrefix}confirm`)}
                  disabled={!selectedMethod}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <GatewayRedirect ref={gatewatRedirectRef} />
      <Oneclick
        ref={oneClickRef}
        onSetLoading={setIsSubmiting}
        onTokenRetreived={(response) => (window.location.href = response?.url)}
      />
      <WebpayPlus
        ref={webpayPlusRef}
        onSetLoading={setIsSubmiting}
        onTokenRetreived={(response) => {
          if (response?.recoveryKey) {
            onRequestSaveState && onRequestSaveState(response?.recoveryKey);
          }
          if (response?.jwt) {
            setOrderJWT(response?.jwt);
          }
          if (response?.anonymousUserEmail) {
            setAnonymousUserEmail(response?.anonymousUserEmail);
          }
          gatewatRedirectRef?.current?.redirect({
            code: "webpay-plus",
            method: "POST",
            action: response?.url,
            inputs: [
              {
                name: "TBK_TOKEN",
                value: response?.token,
              },
              {
                name: "token_ws",
                value: response?.token,
              },
            ],
          });
        }}
      />
      <Dialog />
    </>
  );
};

export default forwardRef(GatewayModal);
