import { Dispatch } from "react";

export enum AppActionTypes {
  RESET_STATE = "RESET_STATE",
  SET_IS_MENU_OPEN = "SET_IS_MENU_OPEN",
}

export type AppAction =
  | { type: AppActionTypes.RESET_STATE }
  | { type: AppActionTypes.SET_IS_MENU_OPEN; payload: boolean | null };

export type AppState = {
  isMenuOpen: boolean | null;
};

export type AppContextType = {
  state: AppState;
  dispatch: Dispatch<AppAction>;
};
