import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "../../config/routes";

type LayoutRoutesProps = {
  pathLayout: string;
  defaultViewPath?: string;
};

/**
 * LayoutRoutes is used to render all routes associated with a layout
 */
const LayoutRoutes = ({
  pathLayout,
  defaultViewPath,
}: LayoutRoutesProps): JSX.Element | null => {
  const layoutRoutes = routes.find((route) => route.layout === pathLayout);

  if (layoutRoutes) {
    return (
      <Switch>
        {layoutRoutes.views.map(({ path, component, params = "" }, key) => (
          <Route
            path={pathLayout + path + (params ? params : "")}
            component={component}
            key={key}
          />
        ))}
        {defaultViewPath && (
          <Redirect from="*" to={pathLayout + defaultViewPath} />
        )}
      </Switch>
    );
  } else {
    return null;
  }
};

export default LayoutRoutes;
