import React from "react";
import CustomButton from "./CustomButton";
import { ReactComponent as BackIcon } from "../../assets/svg/icons/back.svg";

interface Props {
  text: string;
  onClick?: () => void;
}

export default function BackBtn({ text, onClick = () => null }: Props) {
  return (
    <CustomButton
      text={text}
      size="xs"
      colorType="secondary"
      rounded
      icon={<BackIcon width={16} height={16} />}
      fullWidth={false}
      onClick={onClick}
    />
  );
}
