import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { Col, Form, FormGroup, Row } from "reactstrap";
import { clean, format, validate as validateRut } from "rut.js";
import { requestGetDebts, requestGroupedDebts } from "../../api/request";
import { PP_IS_UFRO_SYSTEM, RECAPTCHA_SITE_KEY } from "../../config/constants";
import { useUserState } from "../../hooks";
import { AlertErrorType, AlertType } from "../../types/alertError";
import { TypeSetDebts } from "../../types/stepsSystem";
import { t } from "../../utils/utils";
import CustomAlert from "../alert/CustomAlert";
import CustomButton from "../button/CustomButton";
import CustomInput from "../input/CustomInput";
import T from "../translate/T";
import RecentAccounts from "./RecentAccounts";

type InputsSearch = {
  rut: string;
  token: string;
};

export type Step1Props = {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setDebts: TypeSetDebts;
  setCustomer: React.Dispatch<
    React.SetStateAction<{
      rut: string;
      name: string;
    } | null>
  >;
};

/**
 * Step1 is used to search debts associated with a RUT
 * If there are debts, it redirects to the next step
 */
const Step1 = ({ setCurrentStep, setDebts, setCustomer }: Step1Props) => {
  const {
    handleSubmit,
    register,
    formState: { isValid, isSubmitting },
    setValue,
    getValues,
    control,
    reset,
  } = useForm<InputsSearch>({
    mode: "onChange",
  });
  const [errorDebts, setErrorDebts] = useState<AlertErrorType | null>(null);
  const { isLogged } = useUserState();
  const [newRut, setNewRut] = useState<string>();
  let recaptchaRef = React.createRef<ReCAPTCHA | null>();

  const searchDebts = async (values: InputsSearch) => {
    const rut = clean(values.rut).substr(0, clean(values.rut).length - 1);
    const recaptchaToken = values.token;

    try {
      const res = PP_IS_UFRO_SYSTEM
        ? await requestGetDebts(rut, recaptchaToken)
        : await requestGroupedDebts(rut, recaptchaToken);
      const hasDebts =
        res?.data?.debts?.length > 0 || res?.data?.debtsGroups?.length > 0;

      if (res?.data?.customer?.NOMBRE && hasDebts) {
        setCustomer({
          rut: format(values.rut),
          name: res.data.customer.NOMBRE,
        });
        setDebts(res.data.debts || res.data.debtsGroups);
        setCurrentStep(1);
        setErrorDebts(null);
      } else if (res?.data?.customer === null) {
        setErrorDebts({
          type: AlertType.ERROR,
          message: t("account_not_found"),
        });
      } else {
        setErrorDebts({
          type: AlertType.INFO,
          message: t("error_without_debts"),
        });
      }
    } catch (error) {
      setErrorDebts({ type: AlertType.ERROR, message: t("generic_error") });
    } finally {
      if (recaptchaRef.current) {
        reset();
        recaptchaRef.current.reset();
      }
    }
  };

  const formatRut = () => {
    const rut = getValues("rut");
    if (clean(rut).length > 1) {
      setValue("rut", format(clean(getValues("rut"))));
      setNewRut(format(clean(getValues("rut"))));
    } else {
      setValue("rut", clean(getValues("rut")));
      setNewRut(clean(getValues("rut")));
    }
  };

  return (
    <div className="mx-auto container-max-400">
      <Row className="pt-4">
        <Col xs={12}>
          <p className="text-center pt-2 fs-1-25">
            <T tKey="search_info" />
          </p>
        </Col>
      </Row>
      <Row className="px-sm-4">
        <Col xs={12}>
          {errorDebts && (
            <CustomAlert type={errorDebts.type} text={errorDebts.message} />
          )}
        </Col>
        {isLogged && (
          <Col xs={12}>
            <RecentAccounts
              onClickAccount={(rut) => {
                setValue("rut", rut, { shouldValidate: true });
                setNewRut(rut);
              }}
            />
          </Col>
        )}
        <Col xs={12}>
          <Form onSubmit={handleSubmit(searchDebts)}>
            <FormGroup className="pt-4">
              <CustomInput
                label={t("label_rut")}
                name="rut"
                onBlur={formatRut}
                newValue={newRut}
                innerRef={register({
                  required: t("rut_required"),
                  validate: (value: any) => {
                    if (!validateRut(clean(value))) {
                      return t("invalid_rut");
                    } else {
                      return undefined;
                    }
                  },
                })}
              />
            </FormGroup>

            <FormGroup className="pt-4 g-recaptcha-center">
              <Controller
                defaultValue={""}
                control={control}
                name="token"
                rules={{ required: false }}
                render={({ onChange, ref }) => {
                  recaptchaRef = ref;
                  return (
                    <ReCAPTCHA
                      ref={ref}
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={onChange}
                      hl={t("language_code")}
                      size="normal"
                    />
                  );
                }}
              />
            </FormGroup>
            <div className="py-4">
              <CustomButton
                type="submit"
                disabled={!isValid || isSubmitting}
                loading={isSubmitting}
                text={t("search_debts")}
              />
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Step1;
