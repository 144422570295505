import React from "react";
import { t } from "../../utils/utils";
import { ReactComponent as InfoIcon } from "../../assets/svg/icons/info.svg";

export default function NoDebtsMsg() {
  return (
    <div className="d-flex flex-column align-items-center text-center pt-5 pb-3">
      <InfoIcon height={48} width={48} className="svg-secondary" />
      <h4 className="mt-4 mb-4 text-dark" style={{ fontSize: "1.42rem" }}>
        {t(`no_debts_title`)}
      </h4>
      <p style={{ maxWidth: 250, fontSize: "1.14rem" }} className="text-muted">
        {t(`no_debts_description`)}
      </p>
    </div>
  );
}
