import React from "react";
import { FormGroup, FormText } from "reactstrap";

import { t } from "../../utils/utils";
import CustomInput from "../../components/input/CustomInput";

type NewPasswordInputsProps = {
  register: any;
  getValues: any;
  trigger: any;
  errors: any;
};

const NewPasswordInputs = ({
  register,
  getValues,
  errors,
  trigger,
}: NewPasswordInputsProps) => {
  return (
    <>
      <FormGroup>
        <CustomInput
          type="password"
          label={t("password")}
          name="password"
          innerRef={register({
            required: t("required_field"),
            minLength: {
              value: 8,
              message: t("min_length_password"),
            },
          })}
          onChange={() => {
            if (getValues("repeatPassword") !== "") {
              trigger("repeatPassword");
            }
          }}
          tooltip={t("min_length_password")}
        />
        <FormText color="danger">{errors.password?.message}</FormText>
      </FormGroup>
      <FormGroup>
        <CustomInput
          type="password"
          label={t("repeat_password")}
          name="repeatPassword"
          innerRef={register({
            required: t("required_field"),
            validate: (value: string) => {
              if (value !== getValues("password")) {
                return t("validate_repeat_password");
              }
              return undefined;
            },
            minLength: {
              value: 8,
              message: t("min_length_password"),
            },
          })}
        />
        <FormText color="danger">{errors.repeatPassword?.message}</FormText>
      </FormGroup>
    </>
  );
};

export default NewPasswordInputs;
