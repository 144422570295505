export enum AlertType {
  ERROR = "error",
  INFO = "info",
  WARNING = "warning",
}

export type AlertErrorType = {
  type: AlertType;
  message: string;
};
