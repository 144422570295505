import React from "react";
import {
  Form,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Label,
  FormText,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { t } from "../../utils/utils";
import {
  validate as validateRut,
  clean as cleanRut,
  format as formatRut,
} from "rut.js";

import CustomInput from "../../components/input/CustomInput";
import CustomButton from "../../components/button/CustomButton";
import { ReactComponent as SearchIcon } from "../../assets/svg/icons/search.svg";
import { ReactComponent as FilterIcon } from "../../assets/svg/icons/filter.svg";
import { ReactComponent as CaretIcon } from "../../assets/svg/icons/chevron-right.svg";

type InputContainerProps = {
  labelName: string;
  labelTitle: string;
  errorComponent?: React.ReactNode;
};

const InputContainer: React.FC<InputContainerProps> = (props) => {
  return (
    <div
      className={`d-flex flex-column align-items-start justify-content-start m-0 payment-history-input`}
    >
      <Label for={props.labelName}>{props.labelTitle}</Label>
      <div className="d-flex align-items-center w-100">
        {props.children}
        <SearchIcon />
      </div>
      {props.errorComponent}
    </div>
  );
};

type Inputs = {
  rut?: string;
  document?: string;
};

type PaymentHistoryFiltersProps = {
  windowSize: { height: number | null; width: number | null };
  onSubmit: (filters?: Inputs) => void;
  filterValues: Inputs;
  setFilterValues: React.Dispatch<React.SetStateAction<Inputs>>;
};

const PaymentHistoryFilters: React.FC<PaymentHistoryFiltersProps> = (props) => {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const {
    handleSubmit,
    formState,
    register,
    getValues,
    setValue,
    errors,
  } = useForm<Inputs>({
    mode: "onChange",
    defaultValues: {
      rut: props.filterValues.rut,
      document: props.filterValues.document,
    },
  });

  const isMobile =
    props.windowSize.width !== null && props.windowSize.width < 992;

  const toggleDropdown = () => {
    setIsDropdownOpen((isOpen) => !isOpen);
  };

  const onSubmit = async (values: Inputs) => {
    props.setFilterValues(values);
    props.onSubmit(values);
  };

  const formatRutField = () => {
    const rut = getValues("rut");
    if (rut) {
      if (cleanRut(rut).length > 1) {
        setValue("rut", formatRut(cleanRut(rut)));
      } else {
        setValue("rut", cleanRut(rut));
      }
    }
  };

  const formatDocumentNumber = () => {
    const document = getValues("document") || "";
    setValue("document", document.replace(/[^0-9]/g, ""));
  };

  const renderForm = () => {
    return (
      <Form inline onSubmit={handleSubmit(onSubmit)} className="d-flex w-100">
        <div className={`payment-history-form-inner-container w-100`}>
          <InputContainer
            labelName="rut"
            labelTitle={t("rut")}
            errorComponent={
              errors.rut?.message ? (
                <FormText color="danger">{errors.rut.message}</FormText>
              ) : null
            }
          >
            <CustomInput
              placeholder={t("payments_filter_field_inner_label_rut")}
              name="rut"
              onBlur={formatRutField}
              className="w-100"
              inputStyle={{ width: "100%" }}
              innerRef={register({
                validate: (value) =>
                  value !== "" && !validateRut(cleanRut(value))
                    ? t("invalid_rut")
                    : undefined,
              })}
              newValue={props.filterValues.rut}
              renderAnimation={false}
            />
          </InputContainer>
          <InputContainer
            labelName="document"
            labelTitle={t("payments_filter_field_label_document")}
          >
            <CustomInput
              placeholder={t("payments_filter_field_inner_label_document")}
              name="document"
              className="w-100"
              inputStyle={{ width: "100%" }}
              innerRef={register()}
              newValue={props.filterValues.document}
              renderAnimation={false}
              onBlur={formatDocumentNumber}
            />
          </InputContainer>
          <div
            className="d-flex flex-column justify-content-start white-text payment-history-submit"
            style={{ minWidth: "150px" }}
          >
            <Label>&nbsp;</Label>
            <CustomButton
              type="submit"
              text={t("search")}
              disabled={!formState.isValid}
            />
          </div>
        </div>
      </Form>
    );
  };

  return !isMobile ? (
    renderForm()
  ) : (
    <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown} className="">
      <DropdownToggle
        className={`w-100 payment-history-dropdown-toggle ${
          isDropdownOpen && "payment-history-dropdown-open"
        }`}
      >
        <FilterIcon id="filter" />
        <span>{t("payments_filter_button")}</span>
        <CaretIcon id="payment-history-caret" />
      </DropdownToggle>
      <DropdownMenu className="w-100">{renderForm()}</DropdownMenu>
    </Dropdown>
  );
};

export default PaymentHistoryFilters;
