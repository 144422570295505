// Hooks
import { Ref, forwardRef, useCallback, useImperativeHandle } from "react";

// I18n
import { useTranslation } from "react-i18next";

// Utils
import { clean } from "rut.js";
import { addToast } from "@octano/global-ui";

// Types
import { GatewayOpenOptions } from "../";
import { SelectedDebtsType } from "../../UFRO/types";
import { DebtListType } from "../../../../../types/debtType";
import { useRequestPayDebtsMutation } from "../../../../../views/pay/api";

export type OneclickMethods = {
  handle: (options: GatewayOpenOptions) => void;
};

export type OneclickRetreived = {
  url: string;
};

type OneclickProps = {
  onSetLoading?: (next: boolean) => void;
  onTokenRetreived?: (response: OneclickRetreived) => void;
};

// Render
const Oneclick = (
  { onSetLoading, onTokenRetreived }: OneclickProps,
  ref: Ref<OneclickMethods>
) => {
  const { t } = useTranslation();
  const [requestPayDebts] = useRequestPayDebtsMutation();

  const handleToken = useCallback(
    (response: OneclickRetreived) =>
      !!onTokenRetreived && onTokenRetreived(response),
    [onTokenRetreived]
  );

  const setLoading = useCallback(
    (next: boolean) => !!onSetLoading && onSetLoading(next),
    [onSetLoading]
  );

  const debtIndexToERPKey = useCallback(
    (debts: DebtListType, index: string) => {
      const {
        EMPRESA,
        CORRELATIVO,
        CODIGO_CLI,
        CUOTA,
        ORIGEN,
        CORRELATIVO_CAJA,
      } = debts[Number(index)];
      return {
        EMPRESA,
        CORRELATIVO,
        CODIGO_CLI,
        CUOTA,
        ORIGEN,
        CORRELATIVO_CAJA,
      };
    },
    []
  );

  const parseSelectedDebts = useCallback(
    (debts: DebtListType, selecteds: SelectedDebtsType) => {
      const response = Object.entries(selecteds).map(
        ([index, amount]: [string, number]) => {
          return {
            key: debtIndexToERPKey(debts, index),
            amount,
            intAtraso: debts[Number(index)].INTATRASO ?? 0,
            proyectoCodigo: debts[Number(index)].PROYECTO,
          };
        }
      );

      return response?.filter((payloadDebt) => payloadDebt.amount > 0);
    },
    [debtIndexToERPKey]
  );

  const handlePayment = useCallback(
    async (options?: GatewayOpenOptions) => {
      try {
        setLoading(true);
        const payloadDebts = parseSelectedDebts(
          options?.debts || [],
          options?.selectedDebts ?? {}
        );

        const rut = !options?.isLogged
          ? Number(clean(options?.payerRut || "").slice(0, -1))
          : undefined;

        const response = await requestPayDebts([
          !!options?.isLogged,
          {
            debts: payloadDebts,
            rut,
            method: "oneclick",
          },
        ])?.unwrap();
        handleToken({
          url: response?.initURL,
        });
      } catch (_error: any) {
        setLoading(false);
        addToast({
          icon: "error",
          color: "danger",
          text: _error?.data?.message?.trim() || t("unexpected_error_msg"),
        });
      }
    },
    [setLoading, requestPayDebts, parseSelectedDebts, t, handleToken]
  );

  useImperativeHandle(ref, () => ({
    handle: handlePayment,
  }));

  return <></>;
};

export default forwardRef(Oneclick);
