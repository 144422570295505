/**
 * Endpoint para obtener deudas de un usuario
 * @param rut rut del usuario sin puntos ni digito verificador
 */
export const GET_DEBTS = (rut: string, recaptchaToken: string) =>
  `/debts/${rut}?recaptchaToken=${recaptchaToken}`;

export const GET_GROUPED_DEBTS = (rut: string, recaptchaToken: string) =>
  `/debts/${rut}/groups?recaptchaToken=${recaptchaToken}`;

export const GET_GROUPED_DEBTS_WITH_AUTH = (rut: string) => {
  return `/debts/${rut}/auth-groups`;
};

/**
 * Endpoint para obtener las deudas pagadas por el usuario
 */
export const GET_PAYMENT_HISTORY = `/debts/payment-history`;

/**
 * Endpoint para crear una cuenta de usuario
 */
export const CREATE_ACCOUNT = "/user/create-account";

/**
 * Endpoint para crear una cuenta de usuario
 */
export const ACTIVATE_ACCOUNT = (token: string) =>
  `/user/activate-account?token=${token}`;

/**
 * Endpoint para iniciar sesión
 */
export const LOGIN = "/auth/signin";

/**
 * Endpoint para obtener la información de perfil del usuario autenticado
 */
export const PROFILE = "/user/me";

/**
 * Endpoint para obtener la información de perfil del usuario autenticado
 */
export const CHANGE_PASSWORD = "/user/password";

/**
 * Endpoint para obtener el listado de los ruts pagados anteriormente por el usuario autenticado
 */
export const RECENT_ACCOUNTS = "/debts/last-payed-ruts";

/**
 * Endpoint para recuperar la clave del usuario (Envía el link para restablecer la contraseña)
 */
export const PASSWORD_RECOVERY = "/auth/password-recovery";

/**
 * Endpoint para restablecer la contraseña
 */
export const RESTORE_PASSWORD = "/auth/restore-password";

/**
 * Endpoint para recuperar el email al que se le restablecerá la contraseña
 */
export const EMAIL_RESTORE_PASSWORD = "/auth/email-restore-password";

/**
 * Endpoint para iniciar el pago con webpay
 */
export const PAY_DEBTS = `/debts/pay`;
export const USER_PAY_DEBTS = `/debts/user-pay`;

/**
 * Endpoint para obtener resultado del pago con webpay
 */
export const GET_PAYMENT_RESULT = (buyOrder: number) =>
  `/debts/payment-result/${buyOrder}`;

/**
 * Endpoint para obtener resultado del pago con webpay para usuario
 * logueado
 */
export const GET_PAYMENT_RESULT_USER = (buyOrder: number) =>
  `/debts/payment-result-user/${buyOrder}`;

/**
 * Endpoint para obtener resultado del pago con webpay
 */
export const SEND_EMAIL_WITH_VOUCHER = (buyOrder: string) =>
  `/voucher/${buyOrder}/email`;

/**
 * Endpoint para obtener resultado del pago con webpay por usuario
 * logueado
 */
export const SEND_EMAIL_WITH_VOUCHER_USER = (buyOrder: string) =>
  `/voucher/${buyOrder}/email-user`;

/**
 * Endpoint para descargar el comprobante en PDF
 */
export const DOWNLOAD_PDF_VOUCHER = (buyOrder: string) =>
  `/voucher/${buyOrder}/download/pdf`;

/**
 * Endpoint para descargar el comprobante en PDF por usuario logueado
 */
export const DOWNLOAD_PDF_VOUCHER_USER = (buyOrder: string) =>
  `/voucher/${buyOrder}/download-user/pdf`;

/**
 * Endpoint para verificar si un RUT se encuentra registrado en el ERP y por ende puede registrarse en el portal
 */
export const VERIFY_RUT = `/auth/search`;
