import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import numeral from "numeral";
import { format as formatDate, parseISO } from "date-fns";
import PaginationComponent from "react-reactstrap-pagination";

import { useWindowSize, useUserState } from "../../hooks";
import { formatPdfFilename, t } from "../../utils/utils";
import { requestGetPaymentHistory } from "../../api/request";
import { customToast } from "../../components/toast/CustomToast";
import PdfDownloadIcon from "../../components/pdfDownloadIcon/PdfDownloadIcon";
import ResultAlert from "../../components/error/ResultAlert";
import PaymentHistoryFilters from "./PaymentHistoryFilters";

type DebtPaymentDto = {
  EMPRESA: string;
  RUT: string;
  CORRELATIVO: number;
  TIPO_DOCUMENTO: string;
  CODIGO_CLI: string;
  DOCUMENTO: string;
  FEC_VENCIMIENTO: string;
  SALDO: number;
  TOTAL: number;
  FEC_TRANSA: string;
  NMB_DOCUMENTO: string;
};

type RequestState = "init" | "loading" | "done";

type SearchDebtsFilters = {
  rut?: string;
  document?: string;
};

const PaymentHistory = () => {
  const { isMobile, windowSize } = useWindowSize();

  const [debts, setDebts] = useState<DebtPaymentDto[]>([]);
  const [requestState, setRequestState] = useState<RequestState>("init");
  const [filterValues, setFilterValues] = useState<SearchDebtsFilters>({});

  const [offset, setOffset] = useState(0);
  const limit = 10;
  const page = Math.floor(offset / limit) + 1;

  const handlePageSelect = (newPage: number) => {
    setOffset((newPage - 1) * limit);
  };

  const { setIsSessionExpired } = useUserState();

  const searchDebts = React.useCallback(
    async (filters?: SearchDebtsFilters) => {
      const handleRquestError = (error: any) => {
        if (error.response?.status === 403) {
          setIsSessionExpired(true);
        } else {
          customToast.error(t("unexpected_error_msg"));
        }
        setRequestState("done");
      };

      try {
        setRequestState("loading");
        const res = await requestGetPaymentHistory(filters);
        setRequestState("done");
        setDebts(res.data.debts);
      } catch (error) {
        handleRquestError(error);
      }
    },
    [setIsSessionExpired]
  );

  useEffect(() => {
    searchDebts();
  }, [searchDebts, setRequestState, setDebts]);

  const usingFilters =
    Boolean(filterValues.rut) || Boolean(filterValues.document);

  if (requestState === "done" && debts.length === 0 && !usingFilters) {
    // Buy order doesn't exist
    return (
      <ResultAlert
        type="info"
        title={t("payments_not_found")}
        instructions={t("payments_not_found_description")}
      />
    );
  }

  if (requestState === "loading") {
    return (
      <div
        className="spinner-border text-secondary spinner-border spinner-border-lg"
        role="status"
      >
        <span className="sr-only">{t("loading")}...</span>
      </div>
    );
  }

  const renderOnDesktop = (Component: React.ReactElement) => {
    return !isMobile && Component;
  };

  const renderValueIfItExists = (value: any, render: Function) => {
    return value && render();
  };

  return (
    <>
      <Row>
        <Col xs={12} className="pb-2">
          <PaymentHistoryFilters
            windowSize={windowSize}
            onSubmit={searchDebts}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
          />
        </Col>
      </Row>
      <Row>
        {usingFilters && debts.length === 0 ? (
          <ResultAlert
            type="warning"
            title={t("payments_filtered_not_found")}
            instructions={t("payments_filtered_not_found_description")}
            containerClassName="d-flex flex-column justify-content-center"
            containerStyle={{ height: "50vh" }}
          />
        ) : (
          <Col xs={12} className="pb-2">
            <div className="table-responsive fixed-header full-viewport-height ">
              <table className="table custom-table">
                <thead>
                  <tr className="text-nowrap text-center">
                    <th colSpan={2}>{t("payment_date")}</th>
                    {renderOnDesktop(<th>{t("expiration_date")}</th>)}
                    {renderOnDesktop(<th>{t("payment_type")}</th>)}
                    {renderOnDesktop(<th>{t("detail")}</th>)}
                    {renderOnDesktop(<th>{t("rut")}</th>)}
                    <th>{t("n_doc")}</th>
                    <th>{t("payment_amount")}</th>
                    <th
                      colSpan={isMobile ? 1 : 2}
                      style={{
                        whiteSpace: isMobile ? "initial" : "break-spaces",
                      }}
                    >
                      {t("buy_order")}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {debts
                    .slice(offset, offset + limit)
                    .map((debt: DebtPaymentDto, i: number) => {
                      const { EMPRESA, RUT, FEC_TRANSA, CORRELATIVO } = debt;
                      const filename = formatPdfFilename(
                        EMPRESA,
                        RUT,
                        FEC_TRANSA,
                        String(CORRELATIVO)
                      );

                      return (
                        <tr key={`debt_${i}`} className="text-center">
                          <td className="border-hover"></td>
                          <td className="text-nowrap">
                            {renderValueIfItExists(debt.FEC_TRANSA, () =>
                              formatDate(parseISO(debt.FEC_TRANSA), "dd-MM-yyy")
                            )}
                          </td>
                          {renderOnDesktop(
                            <td className="text-nowrap">
                              {renderValueIfItExists(debt.FEC_VENCIMIENTO, () =>
                                formatDate(
                                  parseISO(debt.FEC_VENCIMIENTO.substring(0, 10)),
                                  "dd-MM-yyy"
                                )
                              )}
                            </td>
                          )}
                          {renderOnDesktop(<td>{debt.TIPO_DOCUMENTO}</td>)}
                          {renderOnDesktop(<td>{debt.NMB_DOCUMENTO}</td>)}
                          {renderOnDesktop(
                            <td className="text-nowrap">{debt.RUT}</td>
                          )}
                          <td>{debt.DOCUMENTO}</td>
                          <td className="font-weight-bold text-nowrap text-right">
                            $ {numeral(debt.TOTAL).format()}
                          </td>
                          {renderOnDesktop(<td>{debt.CORRELATIVO}</td>)}

                          <td className={isMobile ? "" : "text-right"}>
                            <PdfDownloadIcon
                              buyOrder={debt.CORRELATIVO}
                              filename={filename}
                              centered={isMobile}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Col>
        )}
      </Row>
      {debts.length > limit ? (
        <Row>
          <Col className="d-flex justify-content-end">
            <PaginationComponent
              size="sm"
              onSelect={handlePageSelect}
              pageSize={limit}
              totalItems={debts.length}
              defaultActivePage={page}
              firstPageText="<<"
              previousPageText="&nbsp;<&nbsp;"
              nextPageText="&nbsp;>&nbsp;"
              lastPageText=">>"
            />
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default PaymentHistory;
