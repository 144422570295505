import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { format as formatRut } from "rut.js";

import { t, getAnonymousUserEmail, formatPdfFilename } from "../../utils/utils";
import {
  requestGetPaymentResult,
  requestSendVoucherByEmail,
} from "../../api/request";
import CustomButton from "../button/CustomButton";
import WebpaySuccessInfo from "./WebpaySuccessInfo";
import WebpayError from "./WebpayError";
import DownloadPdfVoucherButton from "./DownloadPdfVoucherButton";
import { useUserState } from "../../hooks";

type PaymentResult = {
  EMPRESA: string;
  COD_CLI: string;
  CORRELATIVO: string;
  MONTO: number;
  COD_AUTH: string;
  FEC_TRANSA: string;
  TIPO_PAGO: string;
  CANT_CUOTA: number;
  TIPO_CUOTA: number;
  NRO_TARJETA: string;
  FECHA_DE_COMPRA: string;
  HORA_DE_COMPRA: string;
};

const getPdfFilename = (orderData: PaymentResult) => {
  const { CORRELATIVO, FEC_TRANSA, EMPRESA, COD_CLI } = orderData;
  return formatPdfFilename(
    EMPRESA,
    formatRut(COD_CLI),
    FEC_TRANSA,
    CORRELATIVO
  );
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const isRejectedTransaction = (error: string) => {
  // https://www.transbankdevelopers.cl/referencia/webpay-soap#confirmar-una-transaccion-webpay-plus-normal
  //
  // -1: Rechazo de transacción - Reintente (Posible error en el ingreso de datos de la transacción),
  // -2: Rechazo de transacción (Se produjo fallo al procesar la transacción. Este mensaje de rechazo está relacionado a parámetros de la tarjeta y/o su cuenta asociada),
  // -3: Error en transacción (Interno Transbank),
  // -4: Rechazo emisor (Rechazada por parte del emisor),
  // -5: Rechazo - Posible Fraude (Transacción con riesgo de posible fraude),

  // Omit -3 as a rejection. Treat it as an unkown error.
  return ["-1", "-2", "-4", "-5"].includes(error);
};

const isInternalWebpayError = (error: string) => {
  return error === "-3";
};

const sendEmailAnonymousUser = async (buyOrder: string) => {
  const payerEmail = getAnonymousUserEmail();
  if (payerEmail) {
    // Silently send the email
    requestSendVoucherByEmail(buyOrder, payerEmail, false);
  }
};

const WebpayErrorDetails = () => {
  return (
    <>
      <p className="text-left">{t("webpay_error_rejected_details_desc")}</p>
      <ul className="text-left pl-3">
        <li>{t("webpay_error_rejected_details_1")}</li>
        <li>{t("webpay_error_rejected_details_2")}</li>
        <li>{t("webpay_error_rejected_details_3")}</li>
      </ul>
    </>
  );
};

type WebpayErrorType = "not found" | "unknown error" | "";

const requestResult = async (
  webpayError: string | null,
  webpayBuyOrder: string | null,
  isLogged: boolean,
  setOrderData: (result: PaymentResult | undefined) => void,
  setError: (error: WebpayErrorType) => void
) => {
  if (!webpayError) {
    try {
      setOrderData(undefined);
      setError("");
      const res = await requestGetPaymentResult(
        Number(webpayBuyOrder),
        isLogged
      );
      setOrderData(res.data);
      if (!isLogged && webpayBuyOrder) {
        sendEmailAnonymousUser(webpayBuyOrder);
      }
    } catch (err: any) {
      if (err.response?.status === 404 || err.response?.status === 400) {
        setError("not found");
      } else {
        setError("unknown error");
      }
    }
  }
};

const Step4 = () => {
  const { isLogged } = useUserState();
  const history = useHistory();
  const query = useQuery();
  const webpayBuyOrder = query.get("webpayBuyOrder");
  const webpayError = query.get("webpayError");

  const [orderData, setOrderData] = useState<PaymentResult>();
  const [error, setError] = useState<WebpayErrorType>("");

  const step1Path = "/dashboard/index";

  const request = useCallback(async () => {
    requestResult(
      webpayError,
      webpayBuyOrder,
      isLogged,
      setOrderData,
      setError
    );
  }, [webpayBuyOrder, webpayError, isLogged]);

  useEffect(() => {
    request();
  }, [request]);

  const isLoading = !orderData && !error;

  if (error === "not found") {
    // Buy order doesn't exist
    return (
      <>
        <WebpayError
          title={t("payment_error_not_found")}
          instructions={t("payment_error_not_found_message")}
        />
        <div className="mx-auto container-max-400">
          <Row className="pb-3">
            <Col xs={12} className="">
              <CustomButton
                type="button"
                text={t("go_back_to_first_step")}
                onClick={() => history.push(step1Path)}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }

  const webpayErrorDetails =
    webpayError && isRejectedTransaction(webpayError) ? (
      <WebpayErrorDetails />
    ) : null;

  const webpayErrorTitle =
    webpayError && isRejectedTransaction(webpayError)
      ? t("webpay_rejected_transaction")
      : t("payment_error");

  const paymentErrorInstructions =
    webpayError && isInternalWebpayError(webpayError)
      ? t("webpay_internal_error")
      : t("payment_error_retry_message");

  const filename = orderData && getPdfFilename(orderData);

  return (
    <div>
      {!webpayError && webpayBuyOrder ? (
        <>
          <WebpaySuccessInfo
            error={error}
            orderData={orderData}
            isLoading={isLoading}
            request={request}
          />

          <div className="mx-auto container-max-400">
            <Row className="pb-4">
              <Col xs={12}>
                <div className="mt-4">
                  <DownloadPdfVoucherButton
                    buyOrder={webpayBuyOrder}
                    filename={filename || "download.pdf"}
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div className="mx-auto container-max-400">
            <Row className="pb-3">
              <Col xs={12}>
                <CustomButton
                  type="button"
                  text={t("go_back_to_first_step")}
                  colorType="secondary"
                  onClick={() => history.push(step1Path)}
                />
              </Col>
            </Row>
          </div>
        </>
      ) : null}

      {webpayError ? (
        <>
          <WebpayError
            title={webpayErrorTitle}
            instructions={paymentErrorInstructions}
            details={webpayErrorDetails}
          />
          <div className="mx-auto container-max-400 pt-3">
            <Row className="pb-3">
              <Col xs={12} className="">
                <CustomButton
                  type="button"
                  text={t("retry_payment")}
                  onClick={() => history.push(step1Path)}
                />
              </Col>
            </Row>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Step4;
