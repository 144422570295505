import React from "react";

type Heights = {
  minHeight?: string | number;
  maxHeight?: string | number;
  height?: string | number;
};

type LogoProps = {
  src: string;
  fallbackSrc: string;
  style: Heights;
  alt?: string;
};

const Logo = ({ src, fallbackSrc, alt = "logo", style = {} }: LogoProps) => {
  return (
    <img
      src={src}
      alt={alt}
      onError={(e) => {
        e.currentTarget.src = fallbackSrc;
      }}
      style={{ width: "100%", ...style }}
    />
  );
};

export default React.memo(Logo);
