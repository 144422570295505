import React from "react";
import CustomModal from "../../components/modals/CustomModal";
import CustomButton from "../../components/button/CustomButton";
import { ReactComponent as CheckIcon } from "../../assets/svg/icons/check-circle.svg";
import { t } from "../../utils/utils";

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
}

export default function RecoveryEmailSentModal({ isOpen, onConfirm }: Props) {
  const dictPrefix = "recovery-email-sent";

  return (
    <CustomModal isOpen={isOpen} closeModal={() => null}>
      <div className="d-flex flex-column align-items-center text-center px-4 py-3">
        <CheckIcon width={50} height={50} className="svg-primary mb-2" />
        <h4 className="my-3 text-dark">{t(`${dictPrefix}-title`)}</h4>
        <p className="my-3" style={{ fontSize: "1.25rem" }}>
          {t(`${dictPrefix}-description`)}
        </p>
        <p className="my-3" style={{ fontSize: "1.25rem" }}>
          {t(`${dictPrefix}-contact-support`, {
            supportEmail: process.env.REACT_APP_CONTACT_EMAIL,
          })}
        </p>
      </div>
      <CustomButton
        text={t(`understood`)}
        onClick={onConfirm}
        className="my-3"
      />
    </CustomModal>
  );
}
