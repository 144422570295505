// Components
import { Button, IconProps } from "@octano/global-ui";
import MethodItem from "./MethodItem";

// Types
type MethodsBlockProps<T> = {
  title?: string | null;
  buttonIcon?: IconProps["name"];
  buttonText?: string | null;
  isLoading?: boolean;
  items?: T[];
  className?: string;
  renderLabel?: (item: T) => string;
  isSelected?: (item: T) => boolean;
  isDestroyable?: (item: T) => boolean;
  onPressItem?: (item: T) => void;
  onPressButton?: () => void;
  onPressDestroy?: (item: T) => void;
};

// Render
const MethodsBlock = <T,>({
  title,
  buttonIcon,
  buttonText,
  isLoading = false,
  items = [],
  className,
  isSelected,
  isDestroyable,
  renderLabel,
  onPressItem,
  onPressButton,
  onPressDestroy,
}: MethodsBlockProps<T>) => {
  return (
    <div
      className={["w-100 gateway-item", className]
        ?.filter((e) => !!e?.trim())
        ?.join(" ")}
    >
      <span className="d-block gateway-title font-weight-bold color-dark text-left w-100 fs-1-1 mb-2">
        {title}
      </span>
      {items?.map((item, i) => (
        <MethodItem
          className="mb-2"
          key={`method-${i}`}
          item={item}
          selected={!!isSelected && isSelected(item)}
          destroyable={!!isDestroyable && isDestroyable(item)}
          renderLabel={renderLabel}
          onPressItem={onPressItem}
          onPressDestroy={onPressDestroy}
        />
      ))}
      {!!buttonText?.trim() && (
        <Button
          style={{ width: "calc(100% - 35px)" }}
          className="mb-2"
          onClick={onPressButton}
          type="button"
          icon={buttonIcon}
          text={buttonText?.trim()}
          outlined
          loading={!!isLoading}
        />
      )}
    </div>
  );
};

export default MethodsBlock;
