// Components
import { Button, Icon } from "@octano/global-ui";

// Types
type MethodItemProps<T> = {
  item: T;
  className?: string;
  selected?: boolean;
  destroyable?: boolean;
  renderLabel?: (item: T) => string;
  onPressItem?: (item: T) => void;
  onPressDestroy?: (item: T) => void;
};

// Render
const MethodItem = <T,>({
  item,
  className,
  selected,
  destroyable,
  renderLabel,
  onPressItem,
  onPressDestroy,
}: MethodItemProps<T>) => {
  return (
    <div
      className={["d-flex w-100 align-items-center", className]
        ?.filter((e) => !!e?.trim())
        ?.join(" ")}
    >
      <div className="flex-fill">
        <Button
          type="button"
          text={renderLabel ? renderLabel(item) : ""}
          className="w-100 gateway-method-button"
          outlined
          icon={
            <img
              alt="webpay-plus"
              style={{ height: 27, width: "auto" }}
              src="/tenant/wpplus.png"
            />
          }
          onClick={() => !!onPressItem && onPressItem(item)}
        />
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          width: 25,
          height: 25,
          marginLeft: 10,
          paddingTop: 2,
        }}
      >
        {selected ? (
          <Icon name="success" size={16} color="primary" />
        ) : (
          <div
            style={{
              width: 16,
              height: 16,
              borderRadius: 8,
              border: "1px solid var(--primary)",
            }}
          />
        )}
      </div>
      {!!destroyable && (
        <button
          className="d-flex justify-content-center align-items-center"
          type="button"
          style={{
            width: 25,
            height: 25,
            position: "absolute",
            right: 10,
            border: "none",
            background: "transparent",
          }}
          onClick={() => !!onPressDestroy && onPressDestroy(item)}
        >
          {!!destroyable && <Icon name="trash" size={16} color="primary" />}
        </button>
      )}
    </div>
  );
};

export default MethodItem;
