import React from "react";
import { Redirect, useLocation } from "react-router-dom";

import { Layouts } from "../../config/constants";
import { useUserState } from "../../hooks";

// Redirects the user to the appropriate step 4
const WebpayErrorRedirect = () => {
  const { isLogged } = useUserState();

  const pathname = isLogged ? Layouts.USER_DASHBOARD : Layouts.DASHBOARD;
  const params = new URLSearchParams(useLocation().search);
  const errorCode = params.get("code");

  return (
    <Redirect
      to={{
        pathname: `${pathname}/index`,
        search: `?webpayError=${errorCode}`,
      }}
    />
  );
};

export default WebpayErrorRedirect;
