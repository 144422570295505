import React from "react";
import { Trans, useTranslation } from "react-i18next";

type TProps = {
  tKey: string;
};

/**
 * Function to translate text based on the tkey param
 * @param tKey this key must exists in the files located on locales folder otherwise the function return the tkey string
 */
const T = ({ tKey }: TProps): React.ReactElement => {
  const { t } = useTranslation();
  return <Trans t={t}>{tKey}</Trans>;
};

export default T;
