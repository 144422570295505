import { AxiosResponse } from "axios";
import { DebtsGroupsResponse } from "../types/debtType";
import {
  getApiHeaders,
  handleError,
  getOrderJWT,
  getAnonymousUserEmail,
} from "../utils/utils";
import API from "./api";
import {
  CREATE_ACCOUNT,
  GET_DEBTS,
  GET_PAYMENT_HISTORY,
  LOGIN,
  PASSWORD_RECOVERY,
  PROFILE,
  RECENT_ACCOUNTS,
  RESTORE_PASSWORD,
  EMAIL_RESTORE_PASSWORD,
  PAY_DEBTS,
  USER_PAY_DEBTS,
  GET_PAYMENT_RESULT,
  SEND_EMAIL_WITH_VOUCHER,
  DOWNLOAD_PDF_VOUCHER,
  ACTIVATE_ACCOUNT,
  GET_PAYMENT_RESULT_USER,
  DOWNLOAD_PDF_VOUCHER_USER,
  SEND_EMAIL_WITH_VOUCHER_USER,
  CHANGE_PASSWORD,
  GET_GROUPED_DEBTS,
  GET_GROUPED_DEBTS_WITH_AUTH,
  VERIFY_RUT,
} from "./endpoints";

/**
 * Obtiene las deudas asociadas a un usuario
 * @param rut sin puntos ni digito verificador
 */
export const requestGetDebts = async (rut: string, recaptchaToken: string) => {
  try {
    return await API.get(GET_DEBTS(rut, recaptchaToken));
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

export const requestGroupedDebts = async (
  rut: string,
  recaptchaToken: string
): Promise<AxiosResponse<DebtsGroupsResponse>> => {
  try {
    return await API.get(GET_GROUPED_DEBTS(rut, recaptchaToken));
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

export const requestGroupedDebtsAuthorized = async (rut: string) => {
  try {
    return await API.get(GET_GROUPED_DEBTS_WITH_AUTH(rut), {
      headers: getApiHeaders(),
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

/**
 * Obtiene los pagos asociados a un usuario pagador
 */
export const requestGetPaymentHistory = async (params?: {
  rut?: string;
  document?: string;
  [key: string]: string | undefined;
}) => {
  try {
    const headers = getApiHeaders();
    if (params) {
      for (const key of Object.keys(params)) {
        if (!params[key]) {
          delete params[key];
        }
      }
    }
    return await API.get(GET_PAYMENT_HISTORY, {
      headers,
      params,
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

/**
 * Utilizado para crear una cuenta de usuario
 */
export const requestCreateAccount = async (
  rut: string,
  name: string,
  lastName: string,
  phone: string,
  email: string,
  password: string
) => {
  try {
    return await API.post(CREATE_ACCOUNT, {
      rut,
      name,
      lastName,
      phone,
      email,
      password,
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

export const requestRUTVerification = async (
  rut: string,
  recaptchaToken: string
) => {
  try {
    return await API.get(VERIFY_RUT, { params: { rut, recaptchaToken } });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

/**
 * Utilizado para activar una cuenta de usuario
 */
export const requestActivateAccount = async (token: string) => {
  try {
    return await API.post(ACTIVATE_ACCOUNT(token));
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

export const requestLogin = async (email: string, password: string) => {
  try {
    return await API.post(LOGIN, {
      email,
      password,
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

/**
 * Obtiene la información asociada a un usuario autenticado
 */
export const requestProfile = async () => {
  try {
    const headers = getApiHeaders();
    return await API.get(PROFILE, { headers });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

/**
 * Solicita un cambio de contraseña para un usuario logeado
 */
export const requestChangePassword = async (
  currentPassword: string,
  newPassword: string
) => {
  try {
    const headers = getApiHeaders();
    return await API.patch(
      CHANGE_PASSWORD,
      {
        currentPassword,
        newPassword,
      },
      { headers }
    );
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

/**
 * Obtiene listado con los ruts pagados anteriormente por el usuario autenticado
 */
export const requestRecentAccounts = async () => {
  try {
    const headers = getApiHeaders();
    return await API.get(RECENT_ACCOUNTS, { headers });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

export const requestPasswordRecovery = async (email: string) => {
  try {
    return await API.post(PASSWORD_RECOVERY, {
      email,
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

export const requestRestorePassword = async (
  token: string,
  password: string
) => {
  try {
    return await API.post(RESTORE_PASSWORD, {
      token,
      password,
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

export const requestEmailRestorePassword = async (token: string) => {
  try {
    return await API.post(EMAIL_RESTORE_PASSWORD, {
      token,
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

/**
 * Inicia pago
 */
interface RequestPayDebtsPayload {
  rut?: number;
  debts: {
    key: {
      EMPRESA: string;
      CORRELATIVO: number;
      CODIGO_CLI: string;
      CUOTA: number;
      ORIGEN: string;
      CORRELATIVO_CAJA: number;
    };
    amount: number;
  }[];
}

export const requestPayDebts = async (
  payload: RequestPayDebtsPayload,
  isLogged = false
) => {
  try {
    const headers = isLogged ? getApiHeaders() : {};
    return await API.post<{ token: string; initURL: string; orderJwt: string }>(
      isLogged ? USER_PAY_DEBTS : PAY_DEBTS,
      payload,
      { headers }
    );
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

/**
 * Obtiene resultado de un pago
 */
interface PaymentResult {
  EMPRESA: string;
  COD_CLI: string;
  CORRELATIVO: string;
  MONTO: number;
  COD_AUTH: string;
  FEC_TRANSA: string;
  TIPO_PAGO: string;
  CANT_CUOTA: number;
  TIPO_CUOTA: number;
  NRO_TARJETA: string;
  FECHA_DE_COMPRA: string;
  HORA_DE_COMPRA: string;
}

export const requestGetPaymentResult = async (
  buyOrder: number,
  isLogged: boolean
) => {
  try {
    const headers = getApiHeaders();
    const url = isLogged
      ? GET_PAYMENT_RESULT_USER(buyOrder)
      : GET_PAYMENT_RESULT(buyOrder);
    return await API.get<PaymentResult>(url, {
      headers,
      // used to validate the order number for anonymous users
      params: {
        buyOrderToken: getOrderJWT(),
        anonymousUserEmail: getAnonymousUserEmail(),
      },
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

/**
 * Envia el comprobante de pago adjunto en un correo
 */
export const requestSendVoucherByEmail = async (
  buyOrder: string,
  email: string,
  isLogged: boolean
) => {
  const headers = getApiHeaders();
  const url = isLogged
    ? SEND_EMAIL_WITH_VOUCHER_USER(buyOrder)
    : SEND_EMAIL_WITH_VOUCHER(buyOrder);
  try {
    return await API.post(
      url,
      { email, buyOrderToken: getOrderJWT() },
      { headers }
    );
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

/**
 * Solicita el comprobante de pago en formato PDF
 */
export const requestDownloadPdfVoucher = async (
  buyOrder: string,
  isLogged: boolean
) => {
  try {
    const headers = getApiHeaders();
    const url = isLogged
      ? DOWNLOAD_PDF_VOUCHER_USER(buyOrder)
      : DOWNLOAD_PDF_VOUCHER(buyOrder);
    return await API.get(url, {
      responseType: "blob",
      params: { buyOrderToken: getOrderJWT() },
      headers,
    });
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};
