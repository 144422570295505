import React, { useEffect, useState, useCallback } from "react";
import { Col, Row } from "reactstrap";
import { requestRecentAccounts } from "../../api/request";
import DisplayError from "../error/DisplayError";
import RecentAccountsList from "./RecentAccountsList";
import { useUserState } from "../../hooks";
import { RecentAccountType } from "../../types/debtType";
import { t } from "../../utils/utils";

type RecentAccountsProps = {
  onClickAccount: (rut: string) => void;
};

const RecentAccounts = ({ onClickAccount }: RecentAccountsProps) => {
  const [accounts1, setAccounts1] = useState<RecentAccountType[]>([]);
  const [accounts2, setAccounts2] = useState<RecentAccountType[]>([]);
  const [errorAccounts, setErrorAccounts] = useState(false);
  const [loadingAccounts, setLoadingAccounts] = useState(true);
  const { setIsSessionExpired } = useUserState();

  const getRecentAccounts = useCallback(async () => {
    setLoadingAccounts(true);
    try {
      const res = await requestRecentAccounts();
      const accounts = res.data?.clients;
      if (accounts?.length > 0) {
        setAccounts1(accounts.slice(0, 3));
      }
      if (accounts?.length > 3) {
        setAccounts2(accounts.slice(3, accounts.length));
      }
      setErrorAccounts(false);
    } catch (error: any) {
      if (error.response.status === 403) {
        setIsSessionExpired(true);
      } else {
        setErrorAccounts(true);
      }
    } finally {
      setLoadingAccounts(false);
    }
  }, [setIsSessionExpired]);

  useEffect(() => {
    getRecentAccounts();
  }, [getRecentAccounts]);

  if (!errorAccounts && accounts1.length === 0) {
    return null;
  }
  return (
    <>
      <Row className="mx-1 pt-2">
        <Col xs={12} className="pb-2 text-secondary px-0">
          {t("last_ruts")}
        </Col>
      </Row>
      {errorAccounts ? (
        <DisplayError
          message={t("error_recent_accounts")}
          btnText={t("retry_load")}
          onPressRetry={getRecentAccounts}
          loading={loadingAccounts}
        />
      ) : (
        <RecentAccountsList
          list={accounts1}
          collapsableList={accounts2}
          onClickAccount={onClickAccount}
        />
      )}
    </>
  );
};

export default RecentAccounts;
