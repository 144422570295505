import React from "react";
import { format as formatDate, parseISO } from "date-fns";
import { useWindowSize } from "../../hooks/useWindowSize";
import { t } from "../../utils/utils";
import CustomerInfo from "./CustomerInfo";
import { DebtsGroup } from "../../types/debtType";
import NoDebtsMsg from "./NoDebtsMsg";

export interface PropsGroup {
  onDebtsGroupSelection: (debtsGroup: DebtsGroup) => void;
  customer: {
    rut: string;
    name: string;
  };
  debtsGroups: DebtsGroup[];
}

export default function GroupSelectionStep({
  onDebtsGroupSelection,
  customer,
  debtsGroups,
}: PropsGroup) {
  const { isMobile } = useWindowSize();

  const showOn = (type: "mobile" | "desktop") => {
    if ((isMobile && type === "desktop") || (!isMobile && type === "mobile")) {
      return "d-none";
    } else {
      return "";
    }
  };

  const handlePay = (debtsGroup: DebtsGroup) => {
    onDebtsGroupSelection(debtsGroup);
  };

  return (
    <>
      <CustomerInfo rut={customer.rut} name={customer.name} />
      <div className="table-responsive fixed-header debt-list">
        <table className="table custom-table">
          <thead>
            <tr className="text-nowrap text-center">
              <th colSpan={2} className={showOn("desktop")}>
                {t("details")}
              </th>
              <th className={showOn("desktop")}>{t("n_quota")}</th>
              <th className={showOn("desktop")}>{t("expiration_date")}</th>
              <th className={showOn("desktop")}>{t("total_amount")}</th>

              <th
                className={showOn("mobile")}
                style={{ verticalAlign: "middle" }}
              >
                {t("details")}
              </th>
              <th
                className={showOn("mobile")}
                style={{ maxWidth: 200, whiteSpace: "break-spaces" }}
              >
                {t(`collapsedDebtInfoColumn`)}
              </th>
              <th style={{ verticalAlign: "middle" }}>{t("action")}</th>
            </tr>
          </thead>

          <tbody>
            {debtsGroups.length === 0 && (
              <tr>
                <td colSpan={999}>
                  <NoDebtsMsg />
                </td>
              </tr>
            )}
            {debtsGroups.map((group: DebtsGroup, i: number) => {
              const formattedDate = formatDate(
                parseISO(group.fechaVencimiento.substring(0, 10)),
                "dd-MM-yyyy"
              );

              return (
                <tr key={`debt_${i}`} className="text-center">
                  <td className={`border-hover ${showOn("desktop")}`}></td>

                  <td>{group.name}</td>
                  <td className={showOn("desktop")}>{group.cuota}</td>
                  <td className={showOn("desktop")}>{formattedDate}</td>
                  <td className={`font-weight-bold ${showOn("desktop")}`}>
                    ${group.saldo}
                  </td>
                  <td className={showOn("mobile")}>
                    <span className="font-weight-bold"> ${group.saldo}</span>{" "}
                    <br />
                    {group.cuota} <br />
                    {formattedDate}
                  </td>
                  <td>
                    <button
                      className="btn btn-primary px-4"
                      onClick={() => handlePay(group)}
                    >
                      <span className="d-block px-3">{t("pay")}</span>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
