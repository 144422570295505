import React, { useReducer, createContext } from "react";
import appReducer, { initialState } from "../reducers/appReducer";
import { AppContextType } from "../types/appTypes";

export const AppContext = createContext({} as AppContextType);

const AppProvider = (props: any) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
