import React, { useState } from "react";
import { Col, Collapse, Row } from "reactstrap";
import { RecentAccountType } from "../../types/debtType";
import { t } from "../../utils/utils";

type RecentAccountsListProps = {
  list: RecentAccountType[];
  collapsableList: RecentAccountType[];
  onClickAccount: (rut: string) => void;
};

const RecentAccountsList = ({
  list,
  collapsableList,
  onClickAccount,
}: RecentAccountsListProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {/* Listado de cuentas siempre visible (3 o menos) */}
      {list.map((account) => {
        return (
          <Row className="mx-1" key={`account_${account.rut}`}>
            <Col
              xs={12}
              className="recent-account d-flex justify-content-between align-items-top"
              onClick={() => onClickAccount(account.rut)}
            >
              <span className="font-weight-bold text-nowrap pr-4">
                {account.rut}
              </span>
              <span className="text-capitalize text-truncate">
                {account.name.toLowerCase()}
              </span>
            </Col>
          </Row>
        );
      })}
      {/* Listado de cuentas colapsable */}
      <Collapse isOpen={isOpen}>
        {collapsableList.map((account) => {
          return (
            <Row className="mx-1" key={`account_${account.rut}`}>
              <Col
                xs={12}
                className="recent-account d-flex justify-content-between align-items-center"
                onClick={() => onClickAccount(account.rut)}
              >
                <span className="font-weight-bold">{account.rut}</span>
                <span className="text-capitalize text-truncate">
                  {account.name.toLowerCase()}
                </span>
              </Col>
            </Row>
          );
        })}
      </Collapse>

      {/* Botón para mostrar y esconder el listado de cuentas colapsable */}
      {collapsableList.length > 0 && (
        <Row className="mx-1">
          <Col
            xs={5}
            className="box-more py-1 d-flex justify-content-between align-items-center ml-auto"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span>{isOpen ? t("see_less") : t("see_more_rut")}</span>
            <span className="fs-1-3">{isOpen ? "-" : "+"}</span>
          </Col>
        </Row>
      )}
    </>
  );
};

export default RecentAccountsList;
