import React, { useEffect, useRef } from "react";

const WebpayPostRedirect = ({
  token,
  initURL,
}: {
  token: string;
  initURL: string;
}) => {
  const formRef: any = useRef<HTMLFormElement>(null);
  useEffect(() => {
    formRef.current.submit();
  }, []);
  return (
    <form ref={formRef} method="POST" action={initURL}>
      <input type="hidden" name="TBK_TOKEN" value={token} />
      <input type="hidden" name="token_ws" value={token} />
    </form>
  );
};

export default WebpayPostRedirect;
