import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";
import { clean } from "rut.js";
import { requestPayDebts } from "../../../../api/request";
import { useUserState, useWindowSize } from "../../../../hooks";
import { setAnonymousUserEmail, setOrderJWT, t } from "../../../../utils/utils";
import CustomButton from "../../../button/CustomButton";
import { customToast } from "../../../toast/CustomToast";
import CustomerInfo from "../../CustomerInfo";
import WebpayPostRedirect from "../../WebpayPostRedirect";

import { useDebtsSelection } from "../../../../hooks/useDebtsSelection";
import { DebtsGroup } from "../../../../types/debtType";
import AnonymousUserInputs from "./AnonymousUserInputs";
import TBody from "./TBody";
import { TFooter } from "./TFooter";
import THeader from "./THeader";
import { PayerRut, SelectedDebtsType, Step2Props } from "./types";

const handlePayDebts = async (
  debts: DebtsGroup["debts"],
  selectedDebts: SelectedDebtsType,
  isLogged: boolean,
  payerRut: string,
  payerEmail: string,
  setIsPayLoading: (isLoading: boolean) => void,
  setWebpayRedirect: (data: { token: string; initURL: string }) => void
) => {
  const debtIndexToERPKey = (index: string) => {
    const {
      EMPRESA,
      CORRELATIVO,
      CODIGO_CLI,
      CUOTA,
      ORIGEN,
      CORRELATIVO_CAJA,
    } = debts[Number(index)];
    return {
      EMPRESA,
      CORRELATIVO,
      CODIGO_CLI,
      CUOTA,
      ORIGEN,
      CORRELATIVO_CAJA,
    };
  };

  const debtEntryToPayloadDebt = ([index, amount]: [string, number]) => {
    return {
      key: debtIndexToERPKey(index),
      amount,
      intAtraso: debts[Number(index)].INTATRASO ?? 0,
      proyectoCodigo: debts[Number(index)].PROYECTO,
    };
  };

  const payloadDebts = Object.entries(selectedDebts)
    .map(debtEntryToPayloadDebt)
    .filter((payloadDebt) => payloadDebt.amount > 0);
  const rut = !isLogged ? Number(clean(payerRut).slice(0, -1)) : undefined;
  const payload = {
    debts: payloadDebts,
    rut,
  };
  try {
    setIsPayLoading(true);
    const res = await requestPayDebts(payload, isLogged);
    if (!isLogged && res.data.orderJwt) {
      // Save anonymous order id in jwt to keep track of buy order id
      setOrderJWT(res.data.orderJwt);
      setAnonymousUserEmail(payerEmail);
    }
    setIsPayLoading(false);
    setWebpayRedirect(res.data);
  } catch (err) {
    setIsPayLoading(false);
    customToast.error(t("unexpected_error_msg"));
  }
};

const Step2 = ({ setCurrentStep, customer, debts }: Step2Props) => {
  const { isLogged } = useUserState();
  const { isMobile } = useWindowSize();
  const [isPayLoading, setIsPayLoading] = useState<boolean>(false);
  const [webpayRedirect, setWebpayRedirect] = useState<{
    token: string;
    initURL: string;
  }>();

  const {
    selectedDebts,
    allDebtsCheched,
    setAllDebtsCheched,
    handleCheckAll,
    handleSaldoChange,
    handleCheck,
    getLastSelectedIndex,
  } = useDebtsSelection(isLogged);

  const [payerEmail, setPayerEmail] = useState("");
  useEffect(() => {
    //verifico si todas las deudas estan seleccionadas
    if (Object.keys(selectedDebts).length === debts.length) {
      setAllDebtsCheched(true);
    }
  }, [selectedDebts, debts.length, setAllDebtsCheched]);

  //operación para sumar el total a pagar, segun seleccionadas y el abono
  const sumAmountToPay = (): number => {
    const debtsToSum: number[] = Object.keys(selectedDebts).map((d) => +d);
    return debtsToSum.reduce(
      (debtsSum: number, debt: number) => debtsSum + selectedDebts[debt],
      0
    );
  };

  const totalAmountToPay: number = sumAmountToPay();

  const showOn = (type: "mobile" | "desktop") => {
    if ((isMobile && type === "desktop") || (!isMobile && type === "mobile")) {
      return "d-none";
    } else {
      return "";
    }
  };

  const { register, formState } = useForm<PayerRut>({
    mode: "onChange",
  });

  const isPayButtonEnabled =
    totalAmountToPay > 0 && (isLogged || (customer?.rut && formState.isValid));

  const shouldEnableRow = (rowIdx: number) => {
    const lastSelected = getLastSelectedIndex();
    const haveSelectedSomething = !isNaN(lastSelected);

    const coveredFullAmountOfPreviousDebt =
      selectedDebts[rowIdx - 1] &&
      selectedDebts[rowIdx - 1] === debts[rowIdx - 1].SALDO;

    return haveSelectedSomething && coveredFullAmountOfPreviousDebt
      ? rowIdx <= lastSelected + 1
      : rowIdx === 0;
  };

  const blockingSelectionDebtIdx = useMemo(() => {
    return debts.findIndex((debt: any) => debt.CANAL === "PROT");
  }, [debts]);

  const debtSelectionAllowed = useMemo(() => {
    return blockingSelectionDebtIdx === -1;
  }, [blockingSelectionDebtIdx]);

  return (
    <>
      <CustomerInfo rut={customer.rut} name={customer.name} />
      <Row>
        <Col xs={12} className="pb-3">
          <div className="table-responsive fixed-header debt-list">
            <table className="table custom-table">
              <THeader
                showOn={showOn}
                allDebtsCheched={allDebtsCheched}
                onCheckAll={() => handleCheckAll(debts)}
                blockDebtSelection={!debtSelectionAllowed}
              />
              <TBody
                debts={debts}
                selectedDebts={selectedDebts}
                debtSelectionAllowed={debtSelectionAllowed}
                showOn={showOn}
                shouldEnableRow={shouldEnableRow}
                blockingSelectionDebtIdx={blockingSelectionDebtIdx}
                handleCheck={handleCheck}
                handleSaldoChange={handleSaldoChange}
              />
            </table>
          </div>
          {debts.length === 0 ? null : (
            <TFooter totalAmountToPay={totalAmountToPay} />
          )}
        </Col>
      </Row>

      {debts.length === 0 ? null : (
        <>
          <AnonymousUserInputs
            isLogged={isLogged}
            payerEmail={payerEmail}
            setPayerEmail={setPayerEmail}
            formState={formState}
            register={register}
          />
          <div className="container-max-400 ml-auto">
            <Row>
              <Col xs={6} className="pr-1 pr-sm-2">
                <CustomButton
                  type="button"
                  text={t("cancel")}
                  colorType="secondary"
                  onClick={() => setCurrentStep(0)}
                />
              </Col>
              <Col xs={6} className="pl-1 pl-sm-2">
                <CustomButton
                  type="button"
                  text={t("pay")}
                  disabled={!isPayButtonEnabled}
                  onClick={() =>
                    handlePayDebts(
                      debts,
                      selectedDebts,
                      isLogged,
                      customer.rut,
                      payerEmail,
                      setIsPayLoading,
                      setWebpayRedirect
                    )
                  }
                  loading={isPayLoading}
                />
              </Col>
              {webpayRedirect ? (
                <WebpayPostRedirect {...webpayRedirect} />
              ) : null}
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default Step2;
