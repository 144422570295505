import React from "react";
import { Col, Row } from "reactstrap";
import { ReactComponent as CheckIcon } from "../../assets/svg/icons/check.svg";

type StepsProps = {
  steps: string[];
  currentStep: number;
};

const Steps = ({ steps, currentStep }: StepsProps) => {
  const renderStep = (
    stepNumber: number,
    stepTitle: string,
    status: "ready" | "active" | "pending",
    isLastStep: boolean
  ) => {
    return (
      <Col
        key={`step_${stepNumber}`}
        className={`d-flex p-0 flex-column justify-content-center align-items-center step-container ${
          status !== "pending" ? "active" : ""
        }`}
      >
        {!isLastStep && (
          <div
            className={`d-flex justify-content-center step-line ${status}`}
          ></div>
        )}

        <div style={{ width: "3.2rem", height: "3.2rem", zIndex: 1 }}>
          <div className="step-circle">
            <div className="d-flex justify-content-center align-items-center step-number-container ">
              {status === "ready" ? <CheckIcon /> : stepNumber}
            </div>
          </div>
        </div>
        <span className="title-step pt-1">{stepTitle}</span>
      </Col>
    );
  };

  const getStatusStep = (step: number): "ready" | "active" | "pending" => {
    if (step < currentStep) {
      return "ready";
    }
    if (step === currentStep) {
      return "active";
    }
    return "pending";
  };

  return (
    <Row className="d-flex">
      {steps.map((step, i) =>
        renderStep(i + 1, step, getStatusStep(i), i === steps.length - 1)
      )}
    </Row>
  );
};

export default Steps;
