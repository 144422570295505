import { Dispatch } from "react";

export enum UserActionTypes {
  RESET_STATE = "RESET_STATE",
  SET_IS_LOGGED = "SET_IS_LOGGED",
  SET_PROFILE = "SET_PROFILE",
  SET_IS_SESSION_EXPIRED = "SET_IS_SESSION_EXPIRED",
}

export enum UserSessionEvent {
  EXPIRED = 'user-session-expired',
}

export type ProfileType = {
  id: number;
  rut: string;
  name: string;
  lastName: string;
  phone: string;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type UserAction =
  | { type: UserActionTypes.RESET_STATE }
  | { type: UserActionTypes.SET_IS_LOGGED; payload: boolean }
  | { type: UserActionTypes.SET_PROFILE; payload: ProfileType }
  | { type: UserActionTypes.SET_IS_SESSION_EXPIRED; payload: boolean };

export type UserState = {
  isLogged: boolean;
  profile: ProfileType | null;
  isSessionExpired: boolean;
};

export type UserContextType = {
  state: UserState;
  dispatch: Dispatch<UserAction>;
};
