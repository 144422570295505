import React from "react";
import { Modal, ModalBody } from "reactstrap";
import CustomButton from "../button/CustomButton";

type CustomModalProps = {
  isOpen: boolean;
  size?: "sd" | "md" | "lg";
  icon?: React.ReactElement | null;
  title?: string;
  description?: string;
  textButton?: string | null;
  closeModal: () => void;
  closeOnPressBack?: boolean;
};

const CustomModal: React.FC<CustomModalProps> = ({
  isOpen = true,
  size = "md",
  icon,
  title,
  description,
  textButton,
  closeModal,
  closeOnPressBack = false,
  children,
}) => {
  return (
    <Modal
      size={size}
      isOpen={isOpen}
      centered={true}
      toggle={closeOnPressBack ? closeModal : undefined}
      contentClassName="mx-3 mx-sm-0 rounded-xl"
    >
      <ModalBody className="pb-5 pt-5 px-3 px-sm-5 text-center">
        {icon && <div className="mb-4">{icon}</div>}
        {title && (
          <span className="d-block py-2 px-4 px-sm-1 fs-1-5 color-dark">
            {title}
          </span>
        )}
        {description && (
          <p className="py-2 fs-1-3 color-lighter">{description}</p>
        )}
        {textButton && (
          <CustomButton
            type="button"
            text={textButton}
            onClick={closeModal}
            className="mt-4 w-100"
          />
        )}
        {children}
      </ModalBody>
    </Modal>
  );
};

export default CustomModal;
