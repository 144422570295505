import React from "react";
import { Col, Row } from "reactstrap";
import { t } from "../../utils/utils";

type CustomerInfoProps = {
  rut: string;
  name: string;
};

const CustomerInfo = ({ rut, name }: CustomerInfoProps) => {
  return (
    <div className="mx-auto container-max-400">
      <Row className="px-3 py-4">
        <Col
          xs={12}
          className="text-center my-2 py-3 custom-rounded"
          style={{ backgroundColor: "#F4F4F4" }}
        >
          <p className="mb-1 fs-1-15 color-dark">{t("debts_billed_for")}:</p>
          <span className="text-primary text-uppercase">{name}</span>
          <br />
          <span className="text-primary font-weight-bold">RUT {rut}</span>
        </Col>
      </Row>
    </div>
  );
};

export default CustomerInfo;
