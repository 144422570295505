import React from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup, FormText } from "reactstrap";
import CustomModal from "../modals/CustomModal";
import CustomInput from "../../components/input/CustomInput";
import { t } from "../../utils/utils";
import CustomButton from "../button/CustomButton";
import { requestChangePassword } from "../../api/request";
import { customToast } from "../toast/CustomToast";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

type Inputs = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const ChangePassword: React.FC<Props> = (props) => {
  const {
    handleSubmit,
    register,
    errors,
    getValues,
    formState: { isValid, isSubmitting },
  } = useForm<Inputs>({
    mode: "onChange",
  });

  const onChangePassword = async (inputs: Inputs) => {
    try {
      const res = await requestChangePassword(
        inputs.currentPassword,
        inputs.newPassword
      );
      if (res.status === 200) {
        props.onClose();
        customToast.success(t("change_password_success"));
      } else {
        throw new Error();
      }
    } catch (err: any) {
      if (err?.response?.data?.message === "Wrong current password") {
        customToast.error(t("change_password_error_current_password"));
      } else {
        customToast.error(t("change_password_error"));
      }
    }
  };

  return (
    <CustomModal
      isOpen={props.isOpen}
      title="Cambiar Contraseña"
      closeModal={props.onClose}
    >
      <Form onSubmit={handleSubmit(onChangePassword)}>
        <div style={{ textAlign: "left" }}>
          <FormGroup className="pt-5">
            <CustomInput
              type="password"
              label={t("change_password_field_current_password")}
              name="currentPassword"
              innerRef={register({
                required: t("required_field"),
                minLength: {
                  value: 8,
                  message: t("min_length_password"),
                },
              })}
            />
            <FormText color="danger">
              {errors.currentPassword?.message}
            </FormText>
          </FormGroup>
          <FormGroup className="pt-2">
            <CustomInput
              type="password"
              label={t("change_password_field_new_password")}
              name="newPassword"
              innerRef={register({
                required: t("required_field"),
                minLength: {
                  value: 8,
                  message: t("min_length_password"),
                },
              })}
              tooltip={t("min_length_password")}
              className="change-password-tooltip"
            />
            <FormText color="danger">{errors.newPassword?.message}</FormText>
          </FormGroup>
          <FormGroup className="pt-2">
            <CustomInput
              type="password"
              label={t("change_password_field_confirm_passoword")}
              name="confirmPassword"
              innerRef={register({
                required: t("required_field"),
                validate: (value) => {
                  if (value !== getValues("newPassword")) {
                    return t("validate_repeat_password");
                  }
                  return undefined;
                },
                minLength: {
                  value: 8,
                  message: t("min_length_password"),
                },
              })}
            />
            <FormText color="danger">
              {errors.confirmPassword?.message}
            </FormText>
          </FormGroup>
          <div className="mt-5">
            <CustomButton
              type="submit"
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              text={t("confirm")}
            />
          </div>
          <div className="mt-3 mb-2">
            <CustomButton
              colorType="secondary"
              type="button"
              text={t("cancel")}
              onClick={props.onClose}
            />
          </div>
        </div>
      </Form>
    </CustomModal>
  );
};

export default ChangePassword;
