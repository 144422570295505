import React from "react";
import { Col, FormText, Row } from "reactstrap";
import { clean, format, validate as validateRut } from "rut.js";
import { t } from "../../../../utils/utils";

import CustomInput from "../../../input/CustomInput";
import { AnonymousUserInputsProps } from "./types";

const AnonymousUserInputs = ({
  isLogged,
  payerRut,
  setPayerRut,
  payerEmail,
  setPayerEmail,
  setValue,
  getValues,
  formState,
  register,
}: AnonymousUserInputsProps) => {
  const formatRut = () => {
    const rut = getValues("payerRut");
    if (clean(rut).length > 1) {
      setValue("payerRut", format(clean(getValues("payerRut"))));
      setPayerRut(format(clean(getValues("payerRut"))));
    } else {
      setValue("payerRut", clean(getValues("payerRut")));
      setPayerRut(clean(getValues("payerRut")));
    }
  };
  const rutRef = register({
    required: t("rut_required"),
    validate: (value: string) => {
      if (!validateRut(clean(value))) {
        return t("invalid_rut");
      } else {
        return undefined;
      }
    },
  });
  const emailRef = register({
    required: t("required_field"),
    pattern: {
      //eslint-disable-next-line
      value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      message: t("invalid_email"),
    },
  });

  return !isLogged ? (
    <div className="container-max-600 ml-auto pb-2">
      <Row>
        <Col sm={6} className="pr-3 pb-3 pr-sm-1">
          <CustomInput
            label={t("label_your_rut")}
            name="payerRut"
            onBlur={formatRut}
            newValue={payerRut}
            innerRef={rutRef}
          />
          <FormText color="danger">
            {formState.errors.payerRut?.message}
          </FormText>
        </Col>
        <Col sm={6} className="pr-3 pr-sm-3">
          <CustomInput
            label={t("label_your_email")}
            name="payerEmail"
            onChange={(e: any) => setPayerEmail(e.target.value)}
            newValue={payerEmail}
            innerRef={emailRef}
          />
          <FormText color="danger">
            {formState.errors.payerEmail?.message}
          </FormText>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <p className="mb-0 text-left pt-1 pb-2 fs-1-1">
            {t("anonymous_rut_email_instructions")}
          </p>
        </Col>
      </Row>
    </div>
  ) : null;
};

export default AnonymousUserInputs;
