import React from "react";
import { ReactComponent as WarningIcon } from "../../assets/svg/icons/alert.svg";
import { ReactComponent as RefreshIcon } from "../../assets/svg/icons/reload.svg";
import CustomButton from "../button/CustomButton";

type DisplayErrorProps = {
  message: string;
  btnText: string;
  onPressRetry: () => void;
  loading?: boolean;
};

const DisplayError = ({
  message,
  btnText,
  onPressRetry,
  loading = false,
}: DisplayErrorProps) => {
  return (
    <div className="text-center">
      <WarningIcon className="svg-secondary" width={35} height={35} />
      <p
        className="pt-3 text-center px-2 fs-1-1 color-light"
        style={{ lineHeight: "2rem" }}
      >
        {message}
      </p>
      <CustomButton
        icon={<RefreshIcon width={15} height={15} />}
        text={btnText}
        colorType="secondary"
        type="button"
        onClick={onPressRetry}
        loading={loading}
        size="sm"
      />
    </div>
  );
};

export default DisplayError;
