import React from "react";
import ReactDOM from 'react-dom/client';
import numeral from "numeral";
import "./assets/scss/styles.scss";
import "./config/language";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getOrdinal } from "./utils/utils";

numeral.register("locale", "cl", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "M",
    billion: "b",
    trillion: "t",
  },
  ordinal: getOrdinal,
  currency: {
    symbol: "$",
  },
});

// switch between locales
numeral.locale("cl");

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
