import { format, parseISO } from "date-fns";
import i18next from "i18next";
import {
  JWT_KEY,
  ORDER_JWT_KEY,
  ANONYMOUS_USER_EMAIL,
} from "../config/constants";

/**
 * Function to set user's token
 * @param token
 */
export const setJWT = (token: string) => localStorage.setItem(JWT_KEY, token);
export const setOrderJWT = (token: string) =>
  localStorage.setItem(ORDER_JWT_KEY, token);

/**
 * Function to get user's token
 */
export const getJWT = () => localStorage.getItem(JWT_KEY);

/**
 * Function to get order token
 */
export const getOrderJWT = () => localStorage.getItem(ORDER_JWT_KEY);

/**
 * Function to remove user's token
 */
export const removeJWT = () => localStorage.removeItem(JWT_KEY);

/**
 * Function to set anonymous user email
 */
export const getAnonymousUserEmail = () =>
  localStorage.getItem(ANONYMOUS_USER_EMAIL);

/**
 * Function to get anonymous user email
 */
export const setAnonymousUserEmail = (email: string) =>
  localStorage.setItem(ANONYMOUS_USER_EMAIL, email);

/**
 * Get the text based on the key with the selected language
 * @param key
 * @param params
 */
export const t = (key: string, params?: object) => i18next.t(key, params);

export const handleError = (error: Error) => {
  // manejar error con logger (ej: Sentry)
  console.error(error);
};

/**
 * This function is required to register a new locale to numeral
 * @param number
 */
export const getOrdinal = (number: number): string => {
  var b = number % 10;
  switch (b) {
    case 1:
    case 3:
      return "er";

    case 2:
      return "do";

    case 0:
    case 7:
      return "mo";

    case 8:
      return "vo";

    case 9:
      return "no";

    default:
      return "to";
  }
};

/**
 * Function to get the authorization header with the user token
 */
export const getApiHeaders = () => {
  const jwt = getJWT();
  return {
    authorization: `Bearer ${jwt}`,
  };
};

export const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const formatPdfFilename = (
  company: string,
  rut: string,
  transactionDate: string,
  orderNumber: string
) => {
  const formattedDate = transactionDate
    ? format(parseISO(transactionDate), "dd-MM-yyy")
    : "";
  return `${company} Comprobante de Pago ${rut} ${formattedDate} ${orderNumber}.pdf`;
};
