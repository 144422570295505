export const JWT_KEY: string =
  process.env.REACT_APP_PORTAL_PAGOS_JWT_KEY || "PORTAL_PAGOS_USER_JWT_KEY";
export const ORDER_JWT_KEY: string =
  process.env.REACT_APP_PORTAL_PAGOS_ORDER_JWT_KEY ||
  "PORTAL_PAGOS_ORDER_JWT_KEY";
export const USE_BRAND_COLORS = process.env.REACT_APP_USE_BRAND_COLORS;
export const RECAPTCHA_SITE_KEY: string =
  process.env.REACT_APP_GLOBAL_RECAPTCHA_SITE_KEY || "";
export const ANONYMOUS_USER_EMAIL: string =
  process.env.REACT_APP_ANONYMOUS_USER_EMAIL_KEY || "ANONYMOUS_USER_EMAIL";

export enum Layouts {
  AUTH = "/auth",
  DASHBOARD = "/dashboard",
  USER_DASHBOARD = "/user-dashboard",
  PAYMENT_HISTORY = "/payment-history",
}

export const PP_SYSTEM_TYPE: string = process.env.REACT_APP_PP_SYSTEM_TYPE || 'UFRO'
export const PP_IS_UFRO_SYSTEM: boolean = PP_SYSTEM_TYPE === 'UFRO' || process.env.REACT_APP_PP_IS_SYSTEM_UFRO === 'true'
export const PP_ENABLE_RESTRICTION_REGISTER: boolean = process.env.REACT_APP_PP_RESTRICTION_REGISTER_ACCOUNT_BY_RUT_ENABLE === 'true'
export const PP_ENABLE_RESTRICTION_BY_DEBT_ORDER: boolean = process.env.REACT_APP_PP_RESTRICTION_BY_DEBT_ORDER_ENABLE === 'true'
export const PP_MAX_PAY_STEPS: number = Number(process.env.REACT_APP_PP_MAX_PAY_STEPS) || 3