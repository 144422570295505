import React, { CSSProperties } from "react";
import { Button } from "reactstrap";
import { ReactComponent as CircleIcon } from "../../assets/svg/icons/circle-notch-solid.svg";

interface CustomButtonProps {
  colorType?: "primary" | "secondary";
  text: string;
  type?: "button" | "submit" | "reset";
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  loading?: boolean;
  icon?: React.ReactElement;
  size?: "xs" | "sm" | "lg";
  rounded?: boolean;
  fullWidth?: boolean;
  style?: CSSProperties;
}

/**
 * Componente utilizado para renderizar el botón primary o secondary
 * definido en la guía de estilos (colorType = "primary" | "secondary")
 */
const CustomButton = ({
  colorType = "primary",
  text,
  type = "button",
  className = "",
  onClick,
  disabled = false,
  loading = false,
  icon,
  size = "lg",
  rounded = false,
  fullWidth = true,
  style = {},
}: CustomButtonProps) => {
  return (
    <Button
      block
      color={disabled || loading ? "dark" : "primary"}
      type={type}
      className={`custom-button ${className}`}
      disabled={disabled}
      onClick={onClick}
      outline={colorType === "secondary"}
      size={size}
      style={{
        borderRadius: rounded ? 18 : "",
        width: fullWidth ? "100%" : "fit-content",
        ...style,
      }}
    >
      {loading ? (
        <CircleIcon className="spin fs-2" />
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          {icon && <div className="pr-2 icon-btn">{icon}</div>}
          <span>{text.toUpperCase()}</span>
        </div>
      )}
    </Button>
  );
};

export default CustomButton;
